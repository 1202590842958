import React from 'react'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import MetricHeader from './typography/MetricHeader'
import KeyMetric from './typography/KeyMetric'
import PropTypes from 'prop-types'
import { mobileRender } from '../utils'

const useStyles = makeStyles({
    subCards: {
        height: '100%',
        textAlign: 'center',
        boxShadow: '0px 30px 40px 0px #10245e33',
    },

    greenCard: {
        backgroundColor:'#4c4c78', 
        padding: '10px',
    },
    blueCard: {
        backgroundColor:'#4c4c78', 
        padding: '10px',
    },
})

export default function MetricCard(props) {
    const { heading, bigHeader, single, metricOne, metricTwo, metricOneTitle, metricTwoTitle, color, tabStyle, align } = props
    const [mobile, setMobile] = React.useState(true)

    const classes = useStyles()

    React.useEffect(() => {
        setMobile(mobileRender(window.innerWidth))
    }, [])

    return (
        <div>
            {tabStyle ? (
                <div>
                    <Card
                        // className={color === 'green' ? classes.greenCard : classes.blueCard}
                        style={{
                            width: mobile ? '44%' : '35%',
                            position: 'relative',
                            backgroundColor:'#4c4c78', 
                            borderTopRightRadius: '25px',
                            borderBottomRightRadius: '0px',
                            borderBottomLeftRadius: '0px',
                            boxShadow: 'none',
                            paddingBottom: '0px',
                        }}
                    >
                        <MetricHeader big={bigHeader ? true : false} title={heading} align={align ? align : 'center'} noGutter />
                    </Card>
                    <Card
                        className={color === 'green' ? classes.greenCard : classes.blueCard}
                        style={{ borderTopLeftRadius: '0px', boxShadow: 'none' }}
                    >
                        <Grid container justify="space-around" spacing={3}>
                            <Grid item xs={single ? 12 : 6} style={{ paddingRight: '10px' }}>
                                <Card className={classes.subCards}>
                                    <div style={{ marginTop: '25px', marginBottom: '25px', textAlign: 'center' }}>
                                        <h2 style={{ fontSize: '20px' }}>{metricOneTitle}</h2>
                                        <KeyMetric number={metricOne ? metricOne : '0'} />
                                    </div>
                                </Card>
                            </Grid>
                            {!single ? (
                                <Grid item xs={6} style={{ paddingLeft: '5px' }}>
                                    <Card className={classes.subCards}>
                                        <div style={{ marginTop: '25px', marginBottom: '25px', textAlign: 'center' }}>
                                            <h2 style={{ fontSize: '20px' }}>{metricTwoTitle}</h2>
                                            <KeyMetric number={metricOne ? metricTwo : '0'} />
                                        </div>
                                    </Card>
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Card>
                </div>
            ) : (
                <div>
                    <MetricHeader big={bigHeader ? true : false} title={heading} align="center" />
                    <Card className={color === 'green' ? classes.greenCard : classes.blueCard}>
                        <Grid container justify="space-around" spacing={3}>
                            <Grid item xs={single ? 12 : 6} style={{ paddingRight: single ? '10px' : '5px' }}>
                                <Card className={classes.subCards}>
                                    <div style={{ marginTop: '25px', marginBottom: '25px', textAlign: 'center' }}>
                                        <h2 style={{ fontSize: '20px' }}>{metricOneTitle}</h2>
                                        <KeyMetric number={metricOne ? metricOne : '0'} />
                                    </div>
                                </Card>
                            </Grid>
                            {!single ? (
                                <Grid item xs={6} style={{ paddingLeft: '5px' }}>
                                    <Card className={classes.subCards}>
                                        <div style={{ marginTop: '25px', marginBottom: '25px', textAlign: 'center' }}>
                                            <h2 style={{ fontSize: '20px' }}>{metricTwoTitle}</h2>
                                            <KeyMetric number={metricOne ? metricTwo : '0'} />
                                        </div>
                                    </Card>
                                </Grid>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Card>
                </div>
            )}
        </div>
    )
}

MetricCard.propTypes = {
    bigHeader: PropTypes.bool,
    single: PropTypes.bool,
    heading: PropTypes.string,
    metricOne: PropTypes.string,
    metricTwo: PropTypes.string,
    metricOneTitle: PropTypes.string,
    metricTwoTitle: PropTypes.string,
    color: PropTypes.string,
    align: PropTypes.string,
}
