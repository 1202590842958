import React from 'react'
import { validateAuth } from '../utils/auth'
import moment from 'moment'
import 'chart.js'
// import LineChart from '../components/charts/LineChart'
import { sortBy, sumBy } from 'lodash'
import OrgWrapper from '../components/layouts/OrgWrapper'
import Card from '../components/Card'
import Grid from '@material-ui/core/Grid'
import MetricCard from '../components/MetricCard'
import { BarChart } from 'react-chartkick'
import { numberWithCommas, generateLastThirtyDays, filterByMonth } from '../utils'

const today = moment().format('MM/DD/YYYY')
const thrityDaysAgo = moment().subtract(30, 'days').format('MM/DD/YYYY')

const prev2M = moment().subtract(2, 'months').format('MM')
const prev2MonthTitle = moment().subtract(2, 'months').format('MMM')
const prevM = moment().subtract(1, 'months').format('MM')
const prevMonthTitle = moment().subtract(1, 'months').format('MMM')
const thisM = moment().format('MM')
const thisMonthTitle = moment().format('MMM')
const nextM = moment().add(1, 'months').format('MM')
const nextMonthTitle = moment().add(1, 'months').format('MMM')
// const next2M = moment().add(2, 'months').format('MM')
// const next2MonthTitle = moment().add(2, 'months').format('MMM')

export default function Reports(props) {
    const { history } = props
    const [reservationData, setReservationData] = React.useState([])
    const [filteredData, setFilteredData] = React.useState([])
    // eslint-disable-next-line no-unused-vars
    const [filterMonth, setFilterMonth] = React.useState('07')
    // eslint-disable-next-line no-unused-vars
    const [monthData, setMonthData] = React.useState([])

    const [prevMdata, setPrev] = React.useState([])

    const getReservations = async () => {
        let apiCall = await fetch(`${window.bcaApiUrl}/reservations/all`, {
            method: 'GET',
        })
        let response = await apiCall.json()
        let sorted = sortBy(response, 'id')
        setReservationData(sorted)
        let recentTrips = await generateLastThirtyDays(sorted, today, thrityDaysAgo)
        setFilteredData(recentTrips)
        let monthDt = await filterByMonth(sorted, filterMonth)
        setMonthData(monthDt)
        let prev2Mdata = await filterByMonth(sorted, prev2M)
        let prevMdata = await filterByMonth(sorted, prevM)
        let thisMdata = await filterByMonth(sorted, thisM)
        let nextMdata = await filterByMonth(sorted, nextM)
        // let next2Mdata = await filterByMonth(sorted, next2M)
        setPrev([
            [prev2MonthTitle, prev2Mdata.length],
            [prevMonthTitle, prevMdata.length],
            [thisMonthTitle, thisMdata.length],
            [nextMonthTitle, nextMdata.length],
            // [next2MonthTitle, next2Mdata.length],
        ])
    }

    React.useEffect(() => {
        validateAuth(history)
        getReservations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <OrgWrapper>
            <br />
            <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                    <MetricCard
                        heading="Revenue"
                        metricOne={`$${numberWithCommas(sumBy(reservationData, 'fee'))}`}
                        metricOneTitle="All Time"
                        metricTwo={`$${numberWithCommas(sumBy(filteredData, 'fee'))}`}
                        metricTwoTitle="Last 30 Days"
                        color="green"
                        align="left"
                        tabStyle
                    />
                    <br />
                    <MetricCard
                        heading="Booked Trips"
                        metricOne={reservationData.length}
                        metricOneTitle="All Time"
                        metricTwo={filteredData.length}
                        metricTwoTitle="Last 30 Days"
                        color="blue"
                        align="left"
                        tabStyle
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Card heading="Trips by Month" align="left">
                        <BarChart data={prevMdata} label="Booked Trips" />
                    </Card>
                </Grid>
            </Grid>
        </OrgWrapper>
    )
}
