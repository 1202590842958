import React from 'react';
import { NavLink } from 'react-router-dom'

export default function Breadcrumb() {

let capital = [];
const url = window.location.href;
const cleanUrl = url.replace(/#|_/g, (char) => {
    if (char === '#'){
        return '';
    } 
    return ' ';
});
const pages = cleanUrl.split('/').slice(3);

const toTitleCase = (string) => {
    let stringArray = string.toLowerCase().split(' ');
    stringArray.forEach((item, index) => {
        stringArray[index] = item[0].toUpperCase() + item.slice(1);
        // if loop isn't on last index then add a space to the end of the string
        if (index !== (stringArray.length - 1)) {
            stringArray[index] += ' ';
        }
    });
return stringArray;
};

pages.forEach(page => capital.push({ url: page, name: toTitleCase(page) }));

const renderBack = (pages) => {
    console.log(pages)
    if (pages.length > 1 && pages[0].length < 20){
        return pages[pages.length - 2]
    } else if (pages[0].includes("single-inquiry")){
        return 'inquiries'
    } else if (pages[0].includes("single-reservation?")){
        return 'dashboard'
    }
}
// this logic assumes we will only ever go 3 levels deep. Need to adjust logic if we drill down farther
return (
pages.length > 1 || pages[0].includes("single-reservation") || pages[0].includes("single-inquiry") ? (
    <NavLink
                color="inherit"
                to={{pathname: `/${renderBack(pages)}`}}
                style={{textDecoration:'none'}}>
                {'< Back'}
                
            </NavLink>

) : (
    <div/>
)
            


);
}
