import React from 'react'
import OnboardingLayout from '../onboardingComponents/layout/OnboardingLayout'
import Continue from '../onboardingComponents/input/Continue'
import { NavLink } from 'react-router-dom'

export default function Finished(props) {
    const { changeView, clientInfo, handleNewEntry } = props

    return (
        <OnboardingLayout title={`${clientInfo.name} Saved`} step={5} changeView={changeView}>
            <div>
                <br />
                <br />
                <br />
                <div>
                    <NavLink to={`/dashboard`}>
                        <Continue text="go home" secondary />
                    </NavLink>
                    <br />
                    <br />
                    <Continue text="enter another client" onClick={() => handleNewEntry()} step={5} />
                </div>
            </div>
        </OnboardingLayout>
    )
}
