import React from 'react'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import BarChartIcon from '@material-ui/icons/BarChart'
import TelegramIcon from '@material-ui/icons/Telegram'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import DescriptionIcon from '@material-ui/icons/Description';
import PaymentIcon from '@material-ui/icons/Payment';

const routes = [
    {
        name: 'Dashboard',
        icon: <DashboardIcon />,
        key: 1,
        path: '/dashboard',
    },
    {
        name: 'Inquiries',
        icon: <RecentActorsIcon />,
        key: 2,
        path: '/inquiries',
    },
    {
        name: 'Reports',
        icon: <BarChartIcon />,
        key: 3,
        path: '/reports',
    },
    {
        name: 'Marketing',
        icon: <TelegramIcon />,
        key: 4,
        path: '/marketing',
    },
    {
        name: 'Payments',
        icon: <PaymentIcon />,
        key: 5,
        path: '/payments',
    },
    {
        name: 'Documents',
        icon: <DescriptionIcon />,
        key: 6,
        path: '/documents',
    },
    {
        name: 'Settings',
        icon: <SettingsIcon />,
        key: 7,
        path: '/settings',
    },
    {
        name: 'Help',
        icon: <HelpOutlineIcon />,
        key: 8,
        path: '/dashboard',
        // path: 'help',
    },
    {
        name: 'Logout',
        icon: <ExitToAppIcon />,
        key: 9,
        path: '',
        // path: 'logout',
    },
    {
        name: 'Personal Info',
        key: 10,
        path: '/settings/personal-info',
    },
    {
        name: 'Social Accounts',
        key: 11,
        path: '/marketing/social-accounts',
    },
]

export default routes
