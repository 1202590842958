import React from 'react'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import MetricHeader from './typography/MetricHeader'
import PropTypes from 'prop-types'
import { mobileRender } from '../utils'

const useStyles = makeStyles({
    subCards: {
        height: '100%',
        textAlign: 'center',
        boxShadow: '0px 30px 40px 0px #10245e33',
    },

    greenCard: {
        backgroundColor: '#9fecb370',
        padding: '10px',
    },
    blueCard: {
        backgroundColor: '#9fd3ec70',
        padding: '10px',
        //    zIndex: '10',
    },
})

export default function MetricCard(props) {
    const { children, heading, bigHeader, color, align } = props
    const [mobile, setMobile] = React.useState(true)
    const classes = useStyles()

    React.useEffect(() => {
        setMobile(mobileRender(window.innerWidth))
    }, [])

    return (
        <div>
            <Card
                className={color === 'green' ? classes.greenCard : classes.blueCard}
                style={{
                    width: mobile ? '50%' : '25%',
                    position: 'relative',
                    // top: '8px',
                    borderTopRightRadius: '25px',
                    borderBottomRightRadius: '0px',
                    borderBottomLeftRadius: '0px',
                    boxShadow: 'none',
                    paddingBottom: '0px',
                }}
            >
                <MetricHeader big={bigHeader ? true : false} title={heading} align={align ? align : 'center'} noGutter />
            </Card>
            <Card className={color === 'green' ? classes.greenCard : classes.blueCard} style={{ borderTopLeftRadius: '0px', boxShadow: 'none' }}>
                <Grid container justify="space-around" spacing={3}>
                    <Grid item xs={12} style={{ paddingRight: '10px' }}>
                        <Card className={classes.subCards}>
                            <div style={{ marginTop: '25px', marginBottom: '25px', textAlign: 'center' }}>{children}</div>
                        </Card>
                    </Grid>
                </Grid>
            </Card>
        </div>
    )
}

MetricCard.propTypes = {
    bigHeader: PropTypes.bool,
    heading: PropTypes.string,
    color: PropTypes.string,
    align: PropTypes.string,
}
