import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import './App.css'
import Auth from './views/Auth'
import Home from './views/Home'
import Settings from './views/Settings/Settings'
import Marketing from './views/Marketing/Marketing'
import Documents from './views/Documents/Documents'
import LinkedAccounts from './views/Marketing/LinkedAccounts'
import EmailTemplates from './views/Marketing/EmailTemplates'
import BusinessInfo from './views/Settings/BusinessInfo'
import Payments from './views/Payments/Payments'
import GiftCard from './views/Payments/GiftCard'
import CustomerPayment from './views/Payments/CustomerPayment'

import Reports from './views/Reports'
import Inquiries from './views/Inquiry/Inquiries'
import OnboardingClient from './components/onboarding/onboardingComponents/OnboardingClient'
import InquirySingleView from './views/Inquiry/InquirySingleView'
import SingleReservation from './views/SingleReservation'

function App() {
    const {
        REACT_APP_ENV,
        REACT_APP_BCA_DEV_URL,
        REACT_APP_BCA_PRODUCTION_URL,
        REACT_APP_ZAPIER_OUTGOING_EMAILS,
        REACT_APP_DEV_SQUARE_APPLICATION_ID,
        REACT_APP_PROD_SQUARE_APPLICATION_ID,
        REACT_APP_ZAPIER_GIFT_CARD,
    } = process.env

    // eslint-disable-next-line no-unused-vars
    let history = useHistory()

    if (REACT_APP_ENV === 'development') {
        window.bcaZapEmailUrl = REACT_APP_ZAPIER_OUTGOING_EMAILS
        window.bcaApiUrl = REACT_APP_BCA_DEV_URL
        window.applicationId = REACT_APP_DEV_SQUARE_APPLICATION_ID
        window.bcaGiftUrl = REACT_APP_ZAPIER_GIFT_CARD
        window.env = 'development'
    }
    if (REACT_APP_ENV === 'production') {
        window.bcaApiUrl = REACT_APP_BCA_PRODUCTION_URL
        window.bcaZapEmailUrl = REACT_APP_ZAPIER_OUTGOING_EMAILS
        window.applicationId = REACT_APP_PROD_SQUARE_APPLICATION_ID
        window.bcaGiftUrl = REACT_APP_ZAPIER_GIFT_CARD
        window.env = 'production'
    }

    return (
        <Router>
            <div className="App">
                <Route exact path="/" component={Auth} />
                <Route exact path="/dashboard" component={Home} />
                <Route exact path="/settings" component={Settings} />
                <Route exact path="/marketing" component={Marketing} />
                <Route exact path="/documents" component={Documents} />
                <Route exact path="/payments" component={Payments} />
                <Route path="/customer-payment" component={CustomerPayment} />
                <Route exact path="/gift-card" component={GiftCard} />
                <Route exact path="/marketing/linked-accounts" component={LinkedAccounts} />
                <Route exact path="/marketing/email-templates" component={EmailTemplates} />
                <Route exact path="/settings/business-information" component={BusinessInfo} />
                <Route exact path="/reports" component={Reports} />
                <Route exact path="/inquiries" component={Inquiries} />
                <Route path="/single-inquiry" component={InquirySingleView} />
                <Route path="/single-reservation" component={SingleReservation} />
                <Route exact path="/new-client/" component={OnboardingClient} />
            </div>
        </Router>
    )
}

export default App
