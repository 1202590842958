export const apiGetRequest = (url) => {
    return fetch(`${window.bcaApiUrl}/${url}`, {
		method: 'GET',
	});
}

export const apiPostRequest = (url, data) => {
    return fetch(`${window.bcaApiUrl}/${url}`, {
        method: 'POST',
        body: data ? JSON.stringify(data) : null,
	})
}