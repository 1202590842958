const inputField = {
    padding: '10px',
    width: '50%',
}

const mobileInputField = {
    padding: '10px',
    width: '85%',
    textAlign: 'center',
}

const buttonGroup = {
    padding: '10px',
    width: '50%',
    margin: '0 auto',
}

const mobileButtonGroup = {
    padding: '10px',
    width: '85%',
    margin: '0 auto',
}

const continueButton = {
    marginLeft: '10px',
    width: '63%',
    padding: '10px',
}

const mobileContinueButton = {
    marginLeft: '10px',
    width: '60%',
    padding: '10px',
}

const cancelButton = {
    width: '35%',
    padding: '10px',
}

const confirmText = {
    maxWidth: '50%',
    margin: '0 auto',
    fontSize: '20px',
    lineHeight: '150%',
}

const mobileConfirmText = {
    maxWidth: '92%',
    margin: '0 auto',
    fontSize: '20px',
    lineHeight: '145%',
}

const singleViewFormField = {
    width: '100%',
}

const sideLink = {
    textDecoration: 'none',
    backgroundColor: 'transparent',
}

const activeSideLink = {
    textDecoration: 'none',
    backgroundColor: '#b0c5de',
}

const itemLink = {
    width: 'auto',
    transition: 'all 300ms linear',
    margin: '2px 6px 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px 15px',
    backgroundColor: 'transparent',
    '&:hover,&:focus': {
        backgroundColor: '#c1d0e2',
    }
}

const subPageTitle = {
    marginTop:'0px',
    marginBottom:'3px',
    fontSize:'15px',
    fontWeight:'500'
    // color:'#4c4c78'
}

const subPageItem = {
   width:'100%'
}

const primaryButton = {
    backgroundColor:'#4c4c78', 
    color:'#fff'
}

const viewTitle = {
    fontWeight:'500',
    // marginTop:'50px'
}

export default {
    inputField,
    mobileInputField,
    buttonGroup,
    mobileButtonGroup,
    continueButton,
    mobileContinueButton,
    cancelButton,
    confirmText,
    mobileConfirmText,
    singleViewFormField,
    sideLink,
    activeSideLink,
    itemLink,
    subPageTitle,
    subPageItem,
    primaryButton,
    viewTitle
}
