import React from 'react'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
//import OrgWrapper from '../../../layouts/OrgWrapper'
import { makeStyles } from '@material-ui/core/styles'

//import Back from '../input/Back'
import styles from '../../../../assets/jss/material-onboarding'

const useStyles = makeStyles(styles)

export default function OnboardingLayout(props) {
    const { children, step, title } = props
    const classes = useStyles()
    return (
        <div>
            <LinearProgress variant="determinate" value={step * 20} />
            {/*<div style={{ display: 'flex' }}>
                    <Back previousView={previousView} step={step} changeView={changeView} />
                    <span style={{ marginTop: '15px' }}>Back</span>
                </div>*/}
            {/*<p>{`step ${step} of 4 `}</p>*/}
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                    {/*<div style={{ display: 'flex' }}>
                            <Back previousView={previousView} step={step} changeView={changeView} />
                            <span style={{ marginTop: '15px' }}>Back</span>
                        </div>*/}
                    <h1 className={classes.viewTitle}>{title}</h1>
                    {children}
                </Grid>
            </Grid>
        </div>
    )
}
