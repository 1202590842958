import React , {Fragment}from 'react'
import Grid from '@material-ui/core/Grid'
import SingleInquiryForm from '../../components/forms/SingleInquiryForm'
import OrgWrapper from '../../components/layouts/OrgWrapper'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { submitReservation, emailMerge} from '../../utils'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../assets/jss/material-onboarding'
import { mobileRender } from '../../utils'
import Card from '@material-ui/core/Card'
import EmailModal from '../../components/modals/EmailModal'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const mobile = mobileRender(window.innerWidth)
const useStyles = makeStyles(styles)

export default function InquirySingleView() {
    const [inquiry, setInquiry] = React.useState({ paid: 'Yes' })
    const [modal, setModal] = React.useState(false)
    const [emailModal, setEmailModal] = React.useState(false)
    const [emails, setEmails] = React.useState([])
    const [snackOpen, setSnackOpen] = React.useState(false)
    const [selectedEmail, setSelectedEmail] = React.useState({body: '', subject: ''})
    const classes = useStyles()

    const sendEmail = () => {
        setEmailModal(false)
        fetch(window.bcaZapEmailUrl, {
            method: 'POST',
            body: JSON.stringify({
                subject: selectedEmail.subject,
                body: selectedEmail.body,
                to: 'coltonjl12@gmail.com',
                from: 'baffincabins@gmail.com'
            }),
        }).then((res) => {
           if(res.status === 200){
               setSnackOpen(true)
           }
        })
    }

    const handleSnackClose = () => {
        setSnackOpen(false)
    }

    const authenticateEmailSource = async () => {
        const queryString = await window.location.search
        const urlParams = await new URLSearchParams(queryString)
        const auth = await urlParams.get('auth')
        if (auth === 'baffincabinsemail'){
            sessionStorage.setItem('Auth', 'True')
        }
    }

    const getInquiry = async () => {
        const queryString = await window.location.search
        const urlParams = await new URLSearchParams(queryString)
        const inquiryId = await urlParams.get('id')
        const apiCall = await fetch(`${window.bcaApiUrl}/inquiries/${inquiryId}`, {
            method: 'GET',
        })
        const response = await apiCall.json()
        setInquiry(response)
        //setInquiry({ ...inquiry, paid: 'Yes', paymentMethod: 'Card' })
    }

    const openEmailModal = () => {
        setEmailModal(true)
        setSelectedEmail({...selectedEmail, body: emailMerge(selectedEmail.body, inquiry)})
    }

    const openModal = () => {
        setModal(true)
        setInquiry({ ...inquiry, paid: 'Yes', paymentMethod: 'Card', tip: 0 })
    }

    const handleClose = () => {
        setModal(false)
    }

    const handleEmailClose = () => {
        setEmailModal(false)
    }

    const handleChange = (e) => {
        setInquiry({ ...inquiry, [e.target.id]: e.target.value })
    }

    const submitClient = async (client) => {
        //setLoading(true)
        submitReservation(client).then((res) => {
            setModal(false)
            console.log(res)
        })
    }
    const getEmails = async () => {
        const apiEmailCall = await fetch(`${window.bcaApiUrl}/cs-emails/bca`, {
            method: 'GET',
        })
        const res = await apiEmailCall.json()
        setEmails(res)
        setSelectedEmail(res[0])
    }

    const handleEmailChange = (e) => {
            setSelectedEmail({body: emailMerge(emails[e.target.value].body, inquiry), subject: emails[e.target.value].subject, id: emails[e.target.value].id})
        }

    const handleEmail = (e) => {
        setSelectedEmail({...selectedEmail, [e.target.id]:e.target.value})
    }
  

    React.useEffect(() => {
        getInquiry()
        getEmails()
        authenticateEmailSource()
    }, [])

    const options = (
        <Fragment>
        {emails.map((email, index) => (
            <option key={email.id} value={index}>
            {email.title}
            </option>
        ))}
        </Fragment>
    )

    return (
        <OrgWrapper>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackOpen} autoHideDuration={4000} onClose={handleSnackClose}>
                <MuiAlert elevation={6} variant="filled" severity="success">
                    Email Sent!
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose} style={{ marginLeft: '20px' }}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
            <Grid container>
                <Grid item xs={12}>
                    <h1 style={{marginTop:'5px'}}>{inquiry.name}'s Inquiry</h1>
                </Grid>
                <Grid item xs={6} lg={3} style={{padding:'5px'}}>
                    <a href={`tel:1-${inquiry.phone}`} style={{ textDecoration: 'none', }}>
                        <Button variant="contained" className={classes.primaryButton} style={{  width:'100%'}} >
                            Call Client
                        </Button>
                    </a>
                </Grid>
                <Grid item xs={6} lg={3} style={{padding:'5px'}}>
                    <Button variant="contained" style={{  width:'100%'}} className={classes.primaryButton} onClick={() => openEmailModal()}>
                        Email Client
                    </Button>
                </Grid>
                <Grid item xs={6} lg={3} style={{padding:'5px'}}>
                    <Button variant="contained" style={{ width:'100%'}} className={classes.primaryButton} onClick={() => openModal()}>
                        Mark As Paid
                    </Button>
                    </Grid>
                    <Grid item xs={6} lg={3} style={{padding:'5px'}}>
                    <Button variant="contained" style={{  width:'100%'}}>Archive</Button>
                    </Grid>
            </Grid>
            <EmailModal open={emailModal} handleClose={handleEmailClose} selectedEmail={selectedEmail} handleEmail={handleEmail} handleEmailChange={handleEmailChange} options={options} mobile={mobile} sendEmail={sendEmail}/>
            <br />
            <Dialog open={modal} onClose={handleClose} aria-labelledby="make-paid-modal" aria-describedby="make-paid-modal" style={{margin:'15px !important'}}>
                <DialogContent style={{margin:'0px'}}>
                    <TextField
                        id="fee"
                        style={{ minWidth: '100%', padding:'10px 0px' }}
                        className={mobile ? classes.mobileInputField : classes.inputField}
                        label="Fee Charged"
                        inputProps={{ type: 'number' }}
                        variant="outlined"
                        onChange={(e) => handleChange(e)}
                    />
                    <TextField
                        style={{ minWidth: '100%', padding:'10px 0px'}}
                        className={mobile ? classes.mobileInputField : classes.inputField}
                        label="Internal Note"
                        id="internalNote"
                        variant="outlined"
                        onChange={(e) => handleChange(e)}
                    />
                    <TextField
                        className={mobile ? classes.mobileInputField : classes.inputField}
                        style={{ minWidth: '100%', padding:'10px 0px' }}
                        id="paid"
                        select
                        label="Paid"
                        value={inquiry.paid}
                        onChange={(e) => handleChange(e)}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        <option key="1" value="Yes">
                            Yes
                        </option>
                        <option key="2" value="No">
                            No
                        </option>
                    </TextField>
                    <TextField
                        className={mobile ? classes.mobileInputField : classes.inputField}
                        style={{ minWidth: '100%', padding:'10px 0px', marginBottom:'5px'}}
                        id="paymentMethod"
                        select
                        label="Payment Method"
                        value={inquiry.paymentMethod}
                        onChange={(e) => handleChange(e)}
                        SelectProps={{
                            native: true,
                        }}
                        variant="outlined"
                    >
                        <option key="1" value="Card">
                            Card
                        </option>
                        <option key="2" value="Cash">
                            Cash
                        </option>
                        <option key="2" value="Check">
                            Check
                        </option>
                        <option key="2" value="Split">
                            Split
                        </option>
                    </TextField>
                    <br />
                    <Button variant="contained" className={classes.primaryButton} onClick={() => submitClient(inquiry)} style={{marginLeft:'2px', marginRight:'40px'}} >
                        Submit
                    </Button>
                    <Button variant="contained" onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogContent>
            </Dialog>
            <div>
            <Card style={{paddingTop:'20px', paddingLeft:'10px', paddingBottom:'20px', paddingRight:'10px'}}>
                <SingleInquiryForm inquiry={inquiry} />
            </Card>
            </div>
        </OrgWrapper>
    )
}
