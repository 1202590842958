import React from 'react'
import OnboardingLayout from '../onboardingComponents/layout/OnboardingLayout'
import TextField from '@material-ui/core/TextField'
import Continue from '../onboardingComponents/input/Continue'
import Cancel from '../onboardingComponents/input/Cancel'
import styles from '../../../assets/jss/material-onboarding'
import { makeStyles } from '@material-ui/core/styles'
import { mobileRender } from '../../../utils'

const mobile = mobileRender(window.innerWidth)
const useStyles = makeStyles(styles)

export default function PersonalInfo(props) {
    const { handleChange, changeView, clientInfo } = props
    const classes = useStyles()

    return (
        <OnboardingLayout step={1} title="Customer Info" changeView={changeView}>
            <div>
                <br />
                <TextField
                    required
                    className={mobile ? classes.mobileInputField : classes.inputField}
                    id="name"
                    label="Full Name"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                />
                <br />
                <TextField
                    required
                    className={mobile ? classes.mobileInputField : classes.inputField}
                    id="phone"
                    label="Phone"
                    value={clientInfo.phone}
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                />
                <br />
                <TextField
                    className={mobile ? classes.mobileInputField : classes.inputField}
                    id="email"
                    label="Email"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                />
                <br />
                <TextField
                    className={mobile ? classes.mobileInputField : classes.inputField}
                    label="Internal Note"
                    id="internalNote"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                />
                <br />
                <div className={mobile ? classes.mobileButtonGroup : classes.buttonGroup}>
                    <Cancel changeView={changeView} step={1} />
                    <Continue onClick={(e) => changeView(2)} />
                </div>
            </div>
        </OnboardingLayout>
    )
}
