import {
    Grid,
    TextField,
    InputAdornment,
    Snackbar,
    IconButton,
    FormControlLabel,
    FormControl,
    Radio,
    RadioGroup,
    FormLabel,
    FormHelperText,
    Button,
    Select,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { numberWithCommas } from '../../utils'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import MuiAlert from '@material-ui/lab/Alert'
import CreditCardIcon from '@material-ui/icons/CreditCard'
// import RecentActorsIcon from '@material-ui/icons/RecentActors'
import React from 'react'
import CardPanel from './CardPanel'
import moment from 'moment'

const today = moment().format('MM/DD/YYYY')
const threeYears = moment(today).add(3, 'years').format('MM/DD/YYYY')

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function GiftCard() {
    const [giftCard, setGift] = React.useState({ transportation: 0, party: 2, nights: 2 })
    const [snack, setSnack] = React.useState({ open: false, message: '', error: false })
    const [amount, setAmount] = React.useState()
    const [pay, setMethod] = React.useState({ type: '', open: false })
    const [person, setP] = React.useState({ name: '', email: '' })
    const [allowSubmit, setSubmit] = React.useState({ venmo: false, card: false })
    const [id, setId] = React.useState('')

    const handleChange = (e) => {
        if (e.target.id === 'trans' || e.target.id === 'tran') {
            let us = Number(e.target.value)
            setGift({ ...giftCard, transportation: us })
        } else {
            setGift({ ...giftCard, [e.target.id]: e.target.value })
        }
    }

    const handlePerson = (e) => {
        setP({ ...person, [e.target.id]: e.target.value })
        if (person.name.length > 2 && person.email.includes('@') && person.email.includes('.')) {
            setSubmit({ venmo: true, card: true })
        }
    }

    const handleClose = () => {
        setSnack({ open: false })
    }

    function getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max))
    }

    React.useEffect(() => {
        let y = generateQuote()
        let h = y.length > 3 ? numberWithCommas(y) : y
        setAmount(h)
        let i = getRandomInt(100)
        let r = getRandomInt(9)
        let u = getRandomInt(9)
        let p = getRandomInt(9)
        let q = getRandomInt(9)
        setId(`BCG-${i}${r}${u}${p}${q}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [giftCard.transportation, giftCard.party, giftCard.nights])

    const generateQuote = () => {
        let n = Number(giftCard.nights)
        let p = Number(giftCard.party)
        let nc = n * 250
        let pen = p > 4 ? (p - 4) * 50 : 0
        return nc + pen + giftCard.transportation
    }

    const paymentHandler = (type) => {
        switch (type) {
            case 'card':
                setMethod({ type: 'card', open: true })
                break
            case 'venmo':
                setMethod({ type: 'venmo', open: true })
                break
            default:
                setMethod({ type: 'card', open: true })
        }
    }

    function FormRow() {
        return (
            <React.Fragment>
                <Grid item xs={12} md={4} justify="center" alignContent="center" alignItems="center">
                    <div>
                        <form>
                            <FormControl component="fieldset" style={{ minWidth: '90%' }}>
                                <FormLabel component="legend" style={{ marginBottom: '10px' }}>
                                    1. Transportation
                                </FormLabel>
                                <RadioGroup
                                    aria-label="transportation"
                                    value={giftCard.transportation}
                                    name="transportation"
                                    onChange={handleChange}
                                    id="transportation"
                                >
                                    <FormControlLabel value={0} control={<Radio id="trans" />} label="I have my own boat" />
                                    <FormControlLabel value={300} control={<Radio id="tran" />} label="I do not have a boat" />
                                </RadioGroup>
                                <FormHelperText>We will provide transportation both ways if needed</FormHelperText>
                            </FormControl>
                        </form>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div>
                        <FormControl component="fieldset" style={{ minWidth: '90%' }}>
                            <FormLabel component="legend" style={{ marginBottom: '10px' }}>
                                2. Party Size
                            </FormLabel>
                            <Select
                                native
                                value={giftCard.party}
                                onChange={handleChange}
                                inputProps={{
                                    name: 'Party',
                                    id: 'party',
                                }}
                            >
                                <option aria-label="None" value="" />
                                <option value={1}>One</option>
                                <option value={2}>Two</option>
                                <option value={3}>Three</option>
                                <option value={4}>Four</option>
                                <option value={5}>Five</option>
                                <option value={6}>Six</option>
                            </Select>
                            <FormHelperText>How many people over the age of 12?</FormHelperText>
                        </FormControl>
                    </div>
                </Grid>
                <Grid item xs={12} md={4}>
                    <div>
                        <form style={{ minWidth: '100%' }}>
                            <FormControl component="fieldset" style={{ minWidth: '90%' }}>
                                <FormLabel component="legend" style={{ marginBottom: '10px' }}>
                                    3. Trip Duration
                                </FormLabel>
                                <Select
                                    native
                                    fullWidth
                                    value={giftCard.nights}
                                    onChange={handleChange}
                                    inputProps={{
                                        name: 'night stay',
                                        id: 'nights',
                                    }}
                                >
                                    <option aria-label="None" value="" />
                                    <option value={1}>One</option>
                                    <option value={2}>Two</option>
                                    <option value={3}>Three</option>
                                    <option value={4}>Four</option>
                                    <option value={5}>Five</option>
                                    <option value={6}>Six</option>
                                </Select>
                                <FormHelperText>How many nights on the cabin?</FormHelperText>
                            </FormControl>
                        </form>
                    </div>
                </Grid>
            </React.Fragment>
        )
    }

    return (
        <div style={{ margin: '32px' }}>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snack.open}
                autoHideDuration={4000}
                onClose={handleClose}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                <Alert onClose={handleClose} severity={snack.error ? 'error' : 'success'}>
                    {snack.message}
                </Alert>
            </Snackbar>
            <Grid container justify="center" alignContent="center" spacing={3}>
                <Grid item xs={12} md={9}>
                    <h2 style={{ marginBottom: '25px' }}>1. Gift Card Amount</h2>
                    <Grid container alignContent="center" alignItems="center" spacing={5} style={{ borderRadius: '4px', border: 'black solid 1px' }}>
                        <FormRow />
                        <Grid item style={{ textAlign: 'left' }}>
                            <h3>Subtotal: {`$${amount}`}</h3>
                            <h3>Processing Fee: {`$${amount * 0.03}`}</h3>
                            <h2>Total Charged: {`$${amount + amount * 0.03}`}</h2>
                        </Grid>
                    </Grid>
                    <br />
                    <br />
                    <h2 style={{ marginBottom: '25px' }}>2. Delivery Details</h2>
                    <Grid container alignContent="center" alignItems="center" spacing={5} style={{ borderRadius: '4px', border: 'black solid 1px' }}>
                        <React.Fragment>
                            <Grid item xs={12} md={4} justify="center" alignContent="center" alignItems="center">
                                <TextField
                                    fullWidth
                                    required
                                    id="name"
                                    name="name"
                                    label="Full Name"
                                    value={person.name}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true, id: 'name' }}
                                    onChange={handlePerson}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    required
                                    id="email"
                                    label="Email"
                                    value={person.email}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    onChange={handlePerson}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    disabled
                                    id="amount"
                                    label="Gift Card Amount"
                                    variant="outlined"
                                    onChange={handlePerson}
                                    value={amount}
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AttachMoneyIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </React.Fragment>
                    </Grid>
                    <br />
                    <br />
                    <h2 style={{ marginBottom: '25px' }}>3. Payment Details</h2>
                    <Grid container alignContent="center" spacing={5} style={{ borderRadius: '4px', border: 'black solid 1px' }}>
                        <Grid item md={4}>
                            <Button
                                variant="contained"
                                fullWidth
                                disabled={!allowSubmit.card}
                                onClick={() => paymentHandler('card')}
                                style={{
                                    margin: '10px',
                                    backgroundColor: allowSubmit.card ? '#4A90E2' : '#0000001f',
                                    color: allowSubmit.card ? '#fff' : '#00000042',
                                }}
                                startIcon={<CreditCardIcon />}
                            >
                                Pay With Card
                            </Button>
                            <br />
                            {/* <Button
                                fullWidth
                                variant="contained"
                                style={{ margin: '10px' }}
                                startIcon={<RecentActorsIcon />}
                                disabled={!allowSubmit.venmo}
                            >
                                Pay With Venmo
                            </Button> */}
                            <br />
                            <div style={{ margin: '10px' }}>{pay.open ? <h2>Charge Amount: {`$${amount + amount * 0.03}`}</h2> : ''}</div>
                        </Grid>
                        <Grid item md={8}>
                            <div style={{ margin: '10px' }}>
                                {pay.open ? (
                                    <CardPanel
                                        name={person.name}
                                        email={person.email}
                                        subTotal={amount}
                                        operation="gift"
                                        exp={threeYears}
                                        date={today}
                                        id={id}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </Grid>
                        <br />
                        <br />
                    </Grid>
                    <br />
                </Grid>
            </Grid>
        </div>
    )
}
