import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {apiPostRequest} from '../../utils/api'
import styles from '../../assets/jss/material-onboarding.js';
import {Grid, Button, Card, ListItem, TextField, Divider, Collapse, InputBase} from '@material-ui/core'
import OrgWrapper from '../../components/layouts/OrgWrapper'


const useStyles = makeStyles(styles);

export default function BusinessInfo(){
	const classes = useStyles();


	const initialCollapseState = { Name: false, Email: false, Phone: false, Address: false, Membership: false};

	const [collapseSection, setCollapseSection] = useState(initialCollapseState);
	const [business, setBusiness] = React.useState([])

    const handleCollapse = (section, prev) => {
		prev === true
			? setCollapseSection({
					...collapseSection,
					[section]: false,
			  })
			: setCollapseSection({ ...collapseSection, [section]: true });
	};

	const getOrg = async () => {
		const apiCall = await apiPostRequest('organization', {org:sessionStorage.getItem('token')})
		const res = await apiCall.json()
		setBusiness(res)
	}

	React.useEffect(() => {
		getOrg()
	}, [])

    return(
        <div>
            <OrgWrapper>
            <Grid container>
				<Grid item xs={12} sm={12} md={8}>
					<div>
						<Card>
							<ListItem style={{ display: 'block' }}>
								<h5 className={classes.subPageTitle}>Organization Name</h5>
								<InputBase
								value={business.orgName}
								disabled
								inputProps={{ 'aria-label': 'naked' }}
								/>
							</ListItem>
							<Divider/>
							<ListItem style={{ display: 'block' }}>
								<h5 className={classes.subPageTitle}>Admin</h5>
								<InputBase
								className={classes.subPageItem}
								value={business.admin}
								disabled
								inputProps={{ 'aria-label': 'naked' }}
								/>
							</ListItem>
							<Divider />
							<ListItem
								button
								style={{ display: 'block' }}
								onClick={() => handleCollapse('Membership', collapseSection.Membership)}>
								<h5 className={classes.subPageTitle}>Membership</h5>
								<InputBase
								className={classes.subPageItem}
								value={business.membership}
								disabled
								inputProps={{ 'aria-label': 'naked' }}
								/>
							</ListItem>
							<Collapse in={collapseSection.Membership} timeout="auto" unmountOnExit style={{ paddingLeft: '16px' }}>
								<div style={{ marginTop: '10px' }}>
								<TextField variant="outlined" defaultValue="Gold" style={{width:'95%'}} label="Membership"/>
								<br/>
								<br/>
								<Button className={classes.primaryButton}>Update Membership</Button>
								<Button variant="outlined" style={{ marginLeft:'5%'}} onClick={() => handleCollapse('Membership', collapseSection.Membership)}>Cancel</Button>
								<br/>
								<br/>
								</div>
							</Collapse>
							<Divider />
							<ListItem
								button
								style={{ display: 'block', }}
								onClick={() => handleCollapse('Email', collapseSection.Email)}>
								<h5 className={classes.subPageTitle}>Email</h5>
								<InputBase
								className={classes.subPageItem}
								value={business.email}
								disabled
								inputProps={{ 'aria-label': 'naked' }}
								/>
							</ListItem>
							<Collapse in={collapseSection.Email} timeout="auto" unmountOnExit style={{ paddingLeft: '16px' }}>
								<div style={{ marginTop: '10px' }}>
									<TextField variant="outlined" defaultValue="baffincabins@gmail.com" style={{width:'95%'}} label="Email"/>
								<br/>
								<br/>
								<Button className={classes.primaryButton}>Update Email</Button>
								<Button variant="outlined" style={{ marginLeft:'5%'}} onClick={() => handleCollapse('Email', collapseSection.Email)}>Cancel</Button>
								<br/>
								<br/>
								</div>
							</Collapse>
							<Divider />
							<ListItem
								button
								style={{ display: 'block' }}
								onClick={() => handleCollapse('Phone', collapseSection.Phone)}>
								<h5 className={classes.subPageTitle}>Phone</h5>
								<InputBase
								className={classes.subPageItem}
								value={business.phone}
								disabled
								inputProps={{ 'aria-label': 'naked' }}
								/>
							</ListItem>
							<Collapse in={collapseSection.Phone} timeout="auto" unmountOnExit style={{ paddingLeft: '16px' }}>
							<div style={{ marginTop: '10px' }}>
									<TextField variant="outlined" defaultValue="(361) 790-2791" style={{width:'95%'}} label="Phone"/>
								<br/>
								<br/>
								<Button className={classes.primaryButton}>Update Phone</Button>
								<Button variant="outlined" style={{ marginLeft:'5%'}} onClick={() => handleCollapse('Phone', collapseSection.Phone)}>Cancel</Button>
								<br/>
								<br/>
								</div>
							</Collapse>
							<Divider />
							<ListItem
								button
								style={{ display: 'block' }}
								onClick={() => handleCollapse('Address', collapseSection.Address)}>
								<h5 className={classes.subPageTitle}>Address</h5>
								<InputBase
								className={classes.subPageItem}
								value={`${business.addressOne}${business.addressTwo ? ',' + business.addressTwo : ','} ${business.city}, ${business.state} ${business.zip}`}
								disabled
								inputProps={{ 'aria-label': 'naked' }}
								/>
							</ListItem>
							<Collapse in={collapseSection.Address} timeout="auto" unmountOnExit style={{ paddingLeft: '16px' }}>
								<div style={{ marginTop: '10px' }}>
									<TextField variant="outlined" defaultValue="15830 Punta Espada Loop" style={{width:'95%'}} label="Address One"/>
								<br/>
								<br/>
								<TextField variant="outlined" defaultValue="Corpus Christi" style={{width:'95%'}} label="City"/>
								<br/>
								<br/>
								<TextField variant="outlined" defaultValue="Texas" style={{width:'95%'}} label="State"/>
								<br/>
								<br/>
								<TextField variant="outlined" defaultValue="78418" style={{width:'95%'}} label="Zip"/>
								<br/>
								<br/>
								<Button className={classes.primaryButton}>Update Address</Button>
								<Button variant="outlined" style={{ marginLeft:'5%'}} onClick={() => handleCollapse('Address', collapseSection.Address)}>Cancel</Button>
								<br/>
								<br/>
								</div>
							</Collapse>
							
							<Divider />
						</Card>
					</div>
				</Grid>
			</Grid>
            </OrgWrapper>
        </div>
    )
}