import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
    title: {
        fontSize: '25px',
        marginTop: '0px',
    },
    bigTitle: {
        fontSize: '50px',
        marginTop: '0px',
    },
})

export default function MetricHeader(props) {
    const { align, big, title, noGutter } = props
    const classes = useStyles()

    return (
        <Fragment>
            <h2
                className={big ? classes.bigTitle : classes.title}
                style={{ textAlign: align ? align : 'left', marginBottom: noGutter ? '0px' : '10px', marginLeft:'10px', color:'#fff', paddingTop:'4px'}}
            >
                {title}
            </h2>
        </Fragment>
    )
}

MetricHeader.propTypes = {
    big: PropTypes.bool,
    title: PropTypes.string,
    align: PropTypes.string,
}
