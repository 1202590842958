import React, { Fragment } from 'react'
import Button from '@material-ui/core/Button'
import styles from '../../../../assets/jss/material-onboarding'
import { makeStyles } from '@material-ui/core/styles'
import { mobileRender } from '../../../../utils'

const mobile = mobileRender(window.innerWidth)
const useStyles = makeStyles(styles)

export default function Continue(props) {
    const { text, onClick, secondary } = props
    const classes = useStyles()

    return (
        <Fragment>
            <Button
                variant={secondary ? 'outlined' : 'contained'}
                color={secondary ? '' : 'primary'}
                onClick={onClick}
                className={mobile ? classes.mobileContinueButton : classes.continueButton}
            >
                {text ? text : 'Continue'}
            </Button>
        </Fragment>
    )
}
