import React from 'react'
import { validateAuth } from '../../utils/auth'
import { sortBy } from 'lodash'
import OrgWrapper from '../../components/layouts/OrgWrapper'
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import InvoicesTable from '../../components/InvoicesTable'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'


export default function Payments(props) {
    const { history } = props
    const [loading, setLoading] = React.useState(false)
    // eslint-disable-next-line no-unused-vars
    const [invoices, setInvoices] = React.useState([])
    const [snackOpen, setSnackOpen] = React.useState(false)
    const [newInvoice, setNewInvoice] = React.useState({})
    const [link, setLink] = React.useState('')

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />
    }
    
    function copy() {
        var copyText = document.querySelector("#input");
        copyText.select();
        document.execCommand("copy");
      }
    
    React.useEffect(() => {
        validateAuth(history)
        getInvoices()
        .then(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSnackClose = () => {
        setSnackOpen(false)
    }

    const handleChange = e => {
        setNewInvoice({...newInvoice, [e.target.id]:e.target.value})
    }

    const getInvoices = async () =>{
        const apiCall = await fetch(`${window.bcaApiUrl}/invoices/all`, {
            method: 'GET',
        })
        const invoices = await apiCall.json()
        let sortArray = []
        await invoices.forEach(invoice => {
            let num = invoice.id.split("-")[1]
            sortArray.push({id:parseInt(num)})
        })
        const sorted = sortBy(sortArray, 'id')
        const newNum = sorted[sorted.length - 1].id +1
        const newRan = makeid(5)
        const newId = `bca-${newNum}-${newRan}`
        setNewInvoice({...newInvoice, id:newId, invoice_status:"Outstanding"})
        setInvoices(invoices)
    }

    const submitInvoice = async () =>{
        await fetch(`${window.bcaApiUrl}/invoices`, {
            method: 'POST',
            body: JSON.stringify({
                id: newInvoice.id,
                name: newInvoice.name,
                amount: newInvoice.amount,
                arival_date: moment(newInvoice.arivalDate).format('MM/DD/YYYY'),
                departure_date: moment(newInvoice.departureDate).format('MM/DD/YYYY'),
                invoice_status: newInvoice.invoice_status
            }),
        }).then((res) => {
            setLink(`https://chartersafari.com/customer-payment?invoice-id=${newInvoice.id}`)
            getInvoices()
            console.log(res)
            return res
        })
    }

    function makeid(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

    function onRowClick(e){
        console.log(e)
        let link = document.createElement('input');
        link.id = e[2]
        link.type="text"
        link.setAttribute('value', `https://chartersafari.com/customer-payment?invoice-id=${e[2]}`);
        document.body.appendChild(link);

        // console.log(link)
        let id = `#${e[2]}`
        // let copyText =  document.getElementById(id);
        // console.log(copyText)
        // let text =  copyText.innerHTML
        // console.log(text)

        var copyText = document.querySelector(id);
        console.log(copyText)
        copyText.select();
        document.execCommand("copy");
        
        // copyText.select();
        // document.execCommand("copy");
        // console.log(link)
        document.body.removeChild(link);
        setSnackOpen(true)
    }

    return (
        <OrgWrapper>
            <Backdrop open={loading} style={{ zIndex: 99999 }} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snackOpen}
                autoHideDuration={4000}
                onClose={handleSnackClose}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                <Alert onClose={handleSnackClose} severity="success">
                    Invoice link coppied to clipboard!
                </Alert>
            </Snackbar>
            <h2>Create New Invoice</h2>
            <div>
                <Grid container spacing={3} justify="center">
                    <Grid item xs={12} >
                        <TextField
                            id="name"
                            label="Name"
                            value={newInvoice.name}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => handleChange(e)}
                            style={{padding:'10px'}}
                        />
                        <TextField
                            id="amount"
                            label="Payment Amount"
                            value={newInvoice.amount}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => handleChange(e)}
                            style={{padding:'10px'}}
                        />
                        <TextField
                            id="arivalDate"
                            type="date"
                            variant="outlined"
                            style={{padding:'10px'}}
                            value={moment(newInvoice.arivalDate).format("YYYY-MM-DD")}
                            label="Arival Date mm/dd/yyyy"
                            onChange={(e) => handleChange(e)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                required: true,
                                minLength: 10,
                                maxLength: 10,
                                title: "Please Enter Valid Trip Date",
                            }}
                            />
                        <TextField
                            id="departureDate"
                            type="date"
                            variant="outlined"
                            style={{padding:'10px'}}
                            value={moment(newInvoice.departureDate).format("YYYY-MM-DD")}
                            label="Departure Date mm/dd/yyyy"
                            onChange={(e) => handleChange(e)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                required: true,
                                minLength: 10,
                                maxLength: 10,
                                title: "Please Enter Valid Trip Date",
                            }}
                            />
                    </Grid>
                </Grid>
            </div>
            <Button onClick={submitInvoice} variant="contained" color="primary" style={{margin:'10px'}}>Create Invoice</Button>
            {link.length > 2 ? (
                <div>
                <input id="input" type="text" value={link} style={{minWidth:'40%', marginLeft:'10px'}}/>
                <Button id="copy" onClick={() => copy()} style={{margin:'5px', color:'green'}}  >Copy</Button>
                </div>
            ): <div/>}
            <br/>
            <br/>
            <InvoicesTable data={invoices} onClick={onRowClick} />
        </OrgWrapper>
    )
}
