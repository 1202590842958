import React, {Fragment} from 'react'
import Grid from '@material-ui/core/Grid'
import SingleResForm from '../components/forms/SingleResForm'
import OrgWrapper from '../components/layouts/OrgWrapper'
import Button from '@material-ui/core/Button'
import { emailMerge } from '../utils'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../assets/jss/material-onboarding'
import { mobileRender } from '../utils'
import Card from '@material-ui/core/Card'
import EmailModal from '../components/modals/EmailModal'

const mobile = mobileRender(window.innerWidth)
const useStyles = makeStyles(styles)

export default function SingleReservation() {
    const [reservation, setReservation] = React.useState([])
    const [modal, setModal] = React.useState(false)
    const [emails, setEmails] = React.useState([])
    const [selectedEmail, setSelectedEmail] = React.useState({body: '', subject: ''})

    const classes = useStyles()

    const getReservation = async () => {
        const queryString = await window.location.search
        const urlParams = await new URLSearchParams(queryString)
        const resId = await urlParams.get('id')
        const date = await urlParams.get('date')
        const apiCall = await fetch(`${window.bcaApiUrl}/reservations/${resId}?date=${date}`, {
            method: 'GET',
        })
        const response = await apiCall.json()
        setReservation({...response, checkout: response.reservationDates[response.reservationDates.length  - 1]})
    }

    const openModal = () => {
        setModal(true)
        setSelectedEmail({...selectedEmail, body: emailMerge(selectedEmail.body, reservation)})
    }

    const handleClose = () => {
        setModal(false)
    }

    const getEmails = async () => {
        const apiEmailCall = await fetch(`${window.bcaApiUrl}/cs-emails/bca`, {
            method: 'GET',
        })
        const res = await apiEmailCall.json()
        setEmails(res)
        setSelectedEmail(res[0])
    }

    const handleEmailChange = (e) => {
        setSelectedEmail({body: emailMerge(emails[e.target.value].body, reservation), subject: emails[e.target.value].subject, id: emails[e.target.value].id})
    }

    const handleEmail = (e) => {
        setSelectedEmail({...selectedEmail, [e.target.id]:e.target.value})
    }

    const sendEmail = () => {
        fetch(window.bcaZapEmailUrl, {
            method: 'POST',
            body: JSON.stringify({
                subject: selectedEmail.subject,
                body: selectedEmail.body,
                to: reservation.email,
                from: 'baffincabins@gmail.com'
            }),
        }).then((res) => {
            console.log(res)
            return res
        })
    }

    // const handleChange = (e) => {
    //     setReservation({ ...reservation, [e.target.id]: e.target.value })
    // }

    // const submitClient = async (client) => {
    //     submitReservation(client).then((res) => {
    //         setModal(false)
    //         console.log(res)
    //     })
    // }

    React.useEffect(() => {
    getReservation()
    getEmails()
    }, [])

    const options = (
        <Fragment>
        {emails.map((email, index) => (
            <option key={email.id} value={index}>
            {email.title}
            </option>
        ))}
        </Fragment>
    )

    return (
        <OrgWrapper>
            <Grid container>
                <Grid item xs={12}>
                    <h1 style={{marginTop:'5px'}}>{reservation.name}'s Trip</h1>
                    <a href={`tel:1-${reservation.phone}`} style={{ textDecoration: 'none', marginRight: '8px' }}>
                        <Button variant="contained" style={{width:'35%'}} className={classes.primaryButton}>
                            Call Client
                        </Button>
                    </a>
                    <Button variant="contained" style={{width:'38%', marginRight: '8px'}} className={classes.primaryButton} onClick={() => openModal()}>
                        Email Client
                    </Button>
                    <Button variant="contained" style={{width:'20%'}}>Edit</Button>
                </Grid>
            </Grid>
            <EmailModal open={modal} handleClose={handleClose} selectedEmail={selectedEmail} handleEmail={handleEmail} handleEmailChange={handleEmailChange} options={options} mobile={mobile} sendEmail={sendEmail}/>
            <br />
            <div>
            <Card style={{paddingTop:'20px', paddingLeft:'10px', paddingBottom:'20px', paddingRight:'10px'}}>
                <SingleResForm reservation={reservation} />
            </Card>
            </div>
        </OrgWrapper>
    )
}
