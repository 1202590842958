import React, {Fragment} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Grid, List, Card, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, IconButton} from '@material-ui/core'
import styles from '../../assets/jss/material-onboarding.js';
import OrgWrapper from '../../components/layouts/OrgWrapper'
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import VisibilityIcon from '@material-ui/icons/Visibility';


const useStyles = makeStyles(styles);


export default function Documents() {
    const classes = useStyles();
    

    const internalDocs = (
        <Fragment>
            <ListItem divider>
            <ListItemIcon>
            <DescriptionIcon />
            </ListItemIcon>
            <ListItemText id="switch-list-label-wifi" primary="Lowry & Sons, LLP" />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" style={{margin:'1px'}}>
                    <CloudDownloadIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" style={{margin:'1px'}}>
                    <VisibilityIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" style={{margin:'1px'}}>
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
            <ListItemIcon>
            <DescriptionIcon />
            </ListItemIcon>
            <ListItemText id="switch-list-label-wifi" primary="Lowry & Sons IRS EIN" />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" style={{margin:'1px'}}>
                    <CloudDownloadIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" style={{margin:'1px'}}>
                    <VisibilityIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" style={{margin:'1px'}}>
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
      </Fragment>
    )

    const clientDocs = (
        <Fragment>
            <ListItem divider>
            <ListItemIcon>
            <DescriptionIcon />
            </ListItemIcon>
            <ListItemText id="switch-list-label-wifi" primary="E-sign Waiver Form" />
            <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" style={{margin:'1px'}}>
                    <CloudDownloadIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" style={{margin:'1px'}}>
                    <VisibilityIcon />
                </IconButton>
                <IconButton edge="end" aria-label="delete" style={{margin:'1px'}}>
                    <DeleteIcon />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
      </Fragment>
    )

    return (
        <div>
            <OrgWrapper>

			<Grid container>
				<Grid item xs={12} sm={12} md={8}>
					<div >
                    <h5 style={{marginTop:'5px'}}>Client Documents</h5>
						<Card className={classes.listItem}>
                            <List style={{ padding: '0px' }}>
                          
                          {clientDocs}
                          
                            </List>
						</Card>
					</div>
					<br/>
					<br/>
					<div >
                    <h5 style={{marginTop:'5px'}}>Internal Documents</h5>
                    <Card>

							<List style={{ padding: '0px' }}>
                          
								{internalDocs}
								
							</List>
						</Card>
					</div>
				</Grid>
			</Grid>

            </OrgWrapper>
		</div>
    )
}
