import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/material-onboarding.js';
import {Grid, Button, Card, ListItem, TextField, Divider, Collapse, InputBase} from '@material-ui/core'
import OrgWrapper from '../../components/layouts/OrgWrapper'


const useStyles = makeStyles(styles);

export default function SocialAccounts(){
	const classes = useStyles();


	const initialCollapseState = { facebook: false, insta: false, google: false};

	const [collapseSection, setCollapseSection] = useState(initialCollapseState);

    const handleCollapse = (section, prev) => {
		prev === true
			? setCollapseSection({
					...collapseSection,
					[section]: false,
			  })
			: setCollapseSection({ ...collapseSection, [section]: true });
	};

    return(
        <div>
            <OrgWrapper>
            <Grid container>
				<Grid item xs={12} sm={12} md={8}>
					<div>
						<Card>
							<ListItem button style={{ display: 'block' }} onClick={() => handleCollapse('facebook', collapseSection.facebook)}>
								<h5 className={classes.subPageTitle}>Facebook</h5>
								<InputBase
								className={classes.subPageItem}
								defaultValue="https://www.facebook.com/baffincabins"
								disabled
								inputProps={{ 'aria-label': 'naked' }}
								/>
							</ListItem>
							<Collapse in={collapseSection.facebook} timeout="auto" unmountOnExit style={{ paddingLeft: '16px' }}>
								<div style={{ marginTop: '10px' }}>
								<TextField variant="outlined" defaultValue="https://www.facebook.com/baffincabins" style={{width:'95%'}} label="Facebook"/>
								<br/>
								<br/>
								<Button className={classes.primaryButton}>Update Facebook</Button>
								<Button variant="outlined" style={{ marginLeft:'5%'}} onClick={() => handleCollapse('facebook', collapseSection.facebook)}>Cancel</Button>
								<br/>
								<br/>
								</div>
							</Collapse>
							<Divider/>
							<ListItem button style={{ display: 'block' }} onClick={() => handleCollapse('insta', collapseSection.insta)}>
								<h5 className={classes.subPageTitle}>Instagram</h5>
								<InputBase
								className={classes.subPageItem}
								defaultValue="https://www.instagram.com/baffincabins/"
								disabled
								inputProps={{ 'aria-label': 'naked' }}
								/>
							</ListItem>
							<Collapse in={collapseSection.insta} timeout="auto" unmountOnExit style={{ paddingLeft: '16px' }}>
								<div style={{ marginTop: '10px' }}>
								<TextField variant="outlined" defaultValue="https://www.instagram.com/baffincabins/" style={{width:'95%'}} label="Instagram"/>
								<br/>
								<br/>
								<Button className={classes.primaryButton}>Update Instagram</Button>
								<Button variant="outlined" style={{ marginLeft:'5%'}} onClick={() => handleCollapse('insta', collapseSection.insta)}>Cancel</Button>
								<br/>
								<br/>
								</div>
							</Collapse>
							<Divider />
							<ListItem
								button
								style={{ display: 'block' }}
								onClick={() => handleCollapse('google', collapseSection.google)}>
								<h5 className={classes.subPageTitle}>Google Business</h5>
								<InputBase
								className={classes.subPageItem}
								defaultValue="https://business.google.com/u/2/dashboard/l/07952792965848960746?hl=en"
								disabled
								inputProps={{ 'aria-label': 'naked' }}
								/>
							</ListItem>
							<Collapse in={collapseSection.google} timeout="auto" unmountOnExit style={{ paddingLeft: '16px' }}>
								<div style={{ marginTop: '10px' }}>
								<TextField variant="outlined" defaultValue="https://business.google.com/u/2/dashboard/l/07952792965848960746?hl=en" style={{width:'95%'}} label="Google Business"/>
								<br/>
								<br/>
								<Button className={classes.primaryButton}>Update Google Business</Button>
								<Button variant="outlined" style={{ marginLeft:'5%'}} onClick={() => handleCollapse('google', collapseSection.google)}>Cancel</Button>
								<br/>
								<br/>
								</div>
							</Collapse>
{/* 							
							<Divider />
							<ListItem
								button
								style={{ display: 'block', }}
								onClick={() => handleCollapse('Email', collapseSection.Email)}>
								<h5 className={classes.subPageTitle}>Email</h5>
								<InputBase
								className={classes.subPageItem}
								defaultValue="baffincabins@gmail.com"
								disabled
								inputProps={{ 'aria-label': 'naked' }}
								/>
							</ListItem>
							<Collapse in={collapseSection.Email} timeout="auto" unmountOnExit style={{ paddingLeft: '16px' }}>
								<div style={{ marginTop: '10px' }}>
									<TextField variant="outlined" defaultValue="baffincabins@gmail.com" style={{width:'95%'}} label="Email"/>
								<br/>
								<br/>
								<Button className={classes.primaryButton}>Update Email</Button>
								<Button variant="outlined" style={{ marginLeft:'5%'}}>Cancel</Button>
								<br/>
								<br/>
								</div>
							</Collapse>
							<Divider />
							<ListItem
								button
								style={{ display: 'block' }}
								onClick={() => handleCollapse('Phone', collapseSection.Phone)}>
								<h5 className={classes.subPageTitle}>Phone</h5>
								<InputBase
								className={classes.subPageItem}
								defaultValue="(361) 790-2791"
								disabled
								inputProps={{ 'aria-label': 'naked' }}
								/>
							</ListItem>
							<Collapse in={collapseSection.Phone} timeout="auto" unmountOnExit style={{ paddingLeft: '16px' }}>
							<div style={{ marginTop: '10px' }}>
									<TextField variant="outlined" defaultValue="(361) 790-2791" style={{width:'95%'}} label="Phone"/>
								<br/>
								<br/>
								<Button className={classes.primaryButton}>Update Phone</Button>
								<Button variant="outlined" style={{ marginLeft:'5%'}}>Cancel</Button>
								<br/>
								<br/>
								</div>
							</Collapse>
							<Divider />
							<ListItem
								button
								style={{ display: 'block' }}
								onClick={() => handleCollapse('Address', collapseSection.Address)}>
								<h5 className={classes.subPageTitle}>Address</h5>
								<InputBase
								className={classes.subPageItem}
								defaultValue="15830 Punta Espada Loop, Corpus Christi, TX 78418"
								disabled
								inputProps={{ 'aria-label': 'naked' }}
								/>
							</ListItem>
							<Collapse in={collapseSection.Address} timeout="auto" unmountOnExit style={{ paddingLeft: '16px' }}>
								<div style={{ marginTop: '10px' }}>
									<TextField variant="outlined" defaultValue="15830 Punta Espada Loop" style={{width:'95%'}} label="Address One"/>
								<br/>
								<br/>
								<TextField variant="outlined" defaultValue="Corpus Christi" style={{width:'95%'}} label="City"/>
								<br/>
								<br/>
								<TextField variant="outlined" defaultValue="Texas" style={{width:'95%'}} label="State"/>
								<br/>
								<br/>
								<TextField variant="outlined" defaultValue="78418" style={{width:'95%'}} label="Zip"/>
								<br/>
								<br/>
								<Button className={classes.primaryButton}>Update Address</Button>
								<Button variant="outlined" style={{ marginLeft:'5%'}}>Cancel</Button>
								<br/>
								<br/>
								</div>
							</Collapse>
							
							<Divider /> */}
						</Card>
					</div>
				</Grid>
			</Grid>
            </OrgWrapper>
        </div>
    )
}