import React, { Fragment } from 'react'
//import SideNavigation from '../SideNavigation'
import SideNav from '../SideNav'
import { toTitleCase } from '../../utils'
import styles from '../../assets/jss/material-onboarding'
import { makeStyles } from '@material-ui/core/styles'
import bimg from '../../assets/images/back.svg'
import BreadCrumb from '../BreadCrumb'
const useStyles = makeStyles(styles);

export default function OrgWrapper(props) {
    const { children } = props
    const active = toTitleCase(window.location.pathname.split('/')[1])
    const classes = useStyles()

    return (
        <div style={{ backgroundImage: `url(${bimg})`, minHeight: '100vh', backgroundRepeat:'no', backgroundSize:'cover' }}>
            <SideNav active={active ? active : 'dashboard'}>
            {active === 'Single-inquiry' || active === 'Single-reservation' ? (<Fragment><br/><br/><BreadCrumb /></Fragment>) : (
                <Fragment>
                    <BreadCrumb />
                    <h1 style={{marginBottom:'5px', marginTop:'0px'}} className={classes.viewTitle}>{active}</h1>
                    <br/>
                </Fragment>
                )}
                {children}
            </SideNav>
        </div>
    )
}
