import React, {useState, Fragment} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { getNewTemplateId, getNewTagId } from '../../utils/email'
import styles from '../../assets/jss/material-onboarding.js';
import {Grid, Button, Card, ListItem,  Divider, Collapse,  ListItemText, TextField} from '@material-ui/core'
import OrgWrapper from '../../components/layouts/OrgWrapper'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment'

const useStyles = makeStyles(styles);

function EmailTemplates(){
	const classes = useStyles();
    const [collapseSection, setCollapseSection] = useState({createTemplate:false});
    const [emails, setEmails] = React.useState([])
    const [emailTags, setEmailTags] = React.useState([])
    const [newTag, setNewTag] = React.useState({id:'', tag:'', value:'', create_date: moment().format('MM/DD/YYYY').toString()})
    const [err, setErr] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [newTemplate, setNewTemplate] = React.useState({title:'', subject:'', body:'', id:''})

    const handleCollapse = (section, prev) => {
		prev === true
			? setCollapseSection({
					...collapseSection,
					[section]: false,
			  })
			: setCollapseSection({ ...collapseSection, [section]: true });
    };
    
    const getEmails = async () => {
        const apiCall = await fetch(`${window.bcaApiUrl}/cs-emails/bca`, {
            method: 'GET',
        })
        const res = await apiCall.json()
        setEmails(res)
    }

    const getEmailMergeTags = async () => {
        const apiCall = await fetch(`${window.bcaApiUrl}/cs-email-merge-tags`, {
            method: 'GET',
        })
        const res = await apiCall.json()
        setEmailTags(res)
    }

    const submitNewTag= async () => {
        try {
            setLoading(true)
            handleCollapse('createTag', collapseSection.createTag)
            const apiCall = await fetch(`${window.bcaApiUrl}/cs-email-merge-tags`, {
                method: 'POST',
                body: JSON.stringify({
                    org:'bca',
                    operation: 'create',
                    tag: newTag.tag,
                    value: newTag.value,
                    create_date: newTag.create_date,
                    id: newTag.id
                })
            })
            const response = await apiCall
            if (response.status === 200){
                getEmailMergeTags().then(() => setLoading(false))
            }
        }
        catch {
           setErr(true)
           console.log(err)
        }
    }

    const deleteTag = async (id) => {
        try {
            handleCollapse(id, collapseSection[id])
            const apiCall = await fetch(`${window.bcaApiUrl}/cs-email-merge-tags`, {
                method: 'POST',
                body: JSON.stringify({
                    org:'bca',
                    operation: 'delete',
                    id: id,
                }),
            })
            const response = await apiCall
            if (response.status === 200){
                getEmailMergeTags()
            }
        }
        catch {
           setErr(true)
           console.log(err)
        }
    }

    const handleNewTemp = (e) => {
        setNewTemplate({...newTemplate, [e.target.id]:e.target.value})
    }

    const handleNewTag = (e) => {
        setNewTag({...newTag, [e.target.id]:e.target.value})
    }

    const getTempId = async () => {
        const id = await getNewTemplateId('bca')
        setNewTemplate({...newTemplate, id:id})
    }

    const getTagId = async () => {
        const id = await getNewTagId('bca')
        setNewTag({...newTag, id:id})
    }

    const submitNewTemplate = async () => {
        try {
            setLoading(true)
            handleCollapse('createTemplate', collapseSection.createTemplate)
            const apiCall = await fetch(`${window.bcaApiUrl}/cs-post-emails`, {
                method: 'POST',
                body: JSON.stringify({
                    org:'bca',
                    operation: 'create',
                    template_id: newTemplate.id,
                    title: newTemplate.title,
                    subject: newTemplate.subject,
                    body: newTemplate.body,
                    create_date: moment().format('MM/DD/YYYY').toString(),
                }),
            })
            const response = await apiCall
            if (response.status === 200){
                getEmails().then(() => setLoading(false))
            }
        }
        catch {
           setErr(true)
           console.log(err)
        }
    }

    const deleteTemplate = async (id) => {
        try {
            handleCollapse(id, collapseSection[id])
            const apiCall = await fetch(`${window.bcaApiUrl}/cs-post-emails`, {
                method: 'POST',
                body: JSON.stringify({
                    org:'bca',
                    operation: 'delete',
                    template_id: id,
                }),
            })
            const response = await apiCall
            if (response.status === 200){
                getEmails()
            }
        }
        catch {
           setErr(true)
           console.log(err)
        }
    }

    React.useEffect(() => {
        setLoading(true)
        getEmailMergeTags()
        getEmails().then(() => setLoading(false))
        getTempId()
        getTagId()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const EmailList = (
            <Fragment>
                {emails.map(email => (
              <Fragment>
                <ListItem
                    id={email.id}
                    button
                    divider
                    onClick={(e) => handleCollapse(e.currentTarget.id, collapseSection[e.currentTarget.id])}
                    className={classes.itemLink}
                    style={{ padding: '20px 30px', margin: '0px', color: 'black' }}>
                    <ListItemText primary={email.title} id={email.id} />
                </ListItem>
                <Collapse in={collapseSection[email.id]} timeout="auto" unmountOnExit style={{ paddingLeft: '16px' }}>
                    <div style={{ marginTop: '10px' }}>
                    <br/>{email.subject}
                    <br/>
                    <br/>
                    {email.body}
                    <br/>
                    <br/>
                    <Button className={classes.primaryButton} disabled style={{color:'#fff'}}>Edit</Button>
                    <Button variant="contained" id={email.id} color="secondary" style={{ marginLeft:'2%'}} onClick={(e) => deleteTemplate(e.currentTarget.id)}>Delete</Button>
                    <Button variant="outlined" id={email.id} style={{ marginLeft:'2%'}} onClick={(e) => handleCollapse(e.currentTarget.id, collapseSection[e.currentTarget.id])}>Cancel</Button>
                    <br/>
                    <br/>
                    </div>
                </Collapse>
                <Divider/>
            </Fragment>
                ))}
        </Fragment>
        )

    const mergeTagList = (
        <Fragment>
                {emailTags.map(tag => (
                <Fragment>
                    <div style={{ marginTop: '10px' }}>
                    <br/>
                    <div style={{display:'flex', alignContent:'space-around', alignItems:'center'}}>
                        <TextField variant="outlined" label="Merge Tag" style={{width:'33%', margin:'5px'}} defaultValue={tag.tag} disabled/>
                        <TextField variant="outlined" label="Value" style={{width:'33%', margin:'5px'}} defaultValue={tag.value} disabled/>
                        <div style={{width:'30%', margin:'5px'}}>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                id={tag.id}
                                onClick={(e) => deleteTag(e.currentTarget.id)}
                                startIcon={<DeleteIcon />}
                                style={{ margin:'5px'}}
                            >
                                Delete
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                id={tag.id}
                                startIcon={<CreateIcon />}
                                style={{ margin:'5px'}}

                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                    <br/>
                    </div>
                    <Divider/>
                    </Fragment>
                ))}
        </Fragment>
        )

    return(
        <div>
            <Backdrop open={loading} style={{ zIndex: 99999 }} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <OrgWrapper>
            <Grid container>
				<Grid item xs={12} sm={12} md={8}>
					<div>
                    <h5 style={{marginTop:'5px'}}>Email Templates</h5>
						<Card>
                            {emails ? EmailList : ''}
                        </Card>
                    </div>
                    <br/>
                    <div>
                       <Card>
                       <ListItem
                            id="createTemplate"
                            button
                            divider
                            onClick={(e) => handleCollapse(e.currentTarget.id, collapseSection.createTemplate)}
                            className={classes.itemLink}
                            style={{ padding: '20px 30px', margin: '0px', color: 'black' }}>
                            <ListItemText primary="Create New Template" />
                        </ListItem>
                        <Collapse in={collapseSection.createTemplate} timeout="auto" unmountOnExit style={{ paddingLeft: '16px' }}>
                            <div style={{ marginTop: '10px' }}>
                            <br/>
                            <TextField id="title" label="Template Title" variant="outlined" style={{width:'95%'}} onChange={(e) => handleNewTemp(e)}/>
                            <br/>
                            <br/>
                            <TextField id="subject" label="Email Subject" variant="outlined" style={{width:'95%'}} onChange={(e) => handleNewTemp(e)}/>
                            <br/>
                            <br/>
                            <TextField id="body" label="Email Body" variant="outlined" style={{width:'95%'}} multiline rows={10} onChange={(e) => handleNewTemp(e)}/>
                            <br/>
                            <br/>
                            <Button className={classes.primaryButton} onClick={submitNewTemplate}>Save</Button>
                            <Button id="createTemplate" variant="outlined"  style={{ marginLeft:'2%'}} onClick={(e) => handleCollapse(e.currentTarget.id, collapseSection.createTemplate)}>Cancel</Button>
                            <br/>
                            <br/>
                            </div>
                        </Collapse>
                        <Divider/>
                       </Card>
                   </div>
                    <br/>
                    <br/>
                    <div>
                        <h5 style={{marginTop:'5px'}}>Mangage Merge Tags</h5>
                       <Card>
                       <ListItem
                            id="manageTags"
                            button
                            divider
                            onClick={(e) => handleCollapse(e.currentTarget.id, collapseSection.manageTags)}
                            className={classes.itemLink}
                            style={{ padding: '20px 30px', margin: '0px', color: 'black' }}>
                            <ListItemText primary="Manage Template Tags" />
                        </ListItem>
                        <Collapse in={collapseSection.manageTags} timeout="auto" unmountOnExit style={{ paddingLeft: '16px' }}>
                            {emailTags ? mergeTagList : ''}
                        </Collapse>
                        <Divider/>
                       </Card>
                   </div>
                   <br/>
                    <div>
                       <Card>
                       <ListItem
                            id="createTag"
                            button
                            divider
                            onClick={(e) => handleCollapse(e.currentTarget.id, collapseSection.createTag)}
                            className={classes.itemLink}
                            style={{ padding: '20px 30px', margin: '0px', color: 'black' }}>
                            <ListItemText primary="Create New Tag" />
                        </ListItem>
                        <Collapse in={collapseSection.createTag} timeout="auto" unmountOnExit style={{ paddingLeft: '16px' }}>
                        <div style={{ marginTop: '10px' }}>
                            <br/>
                            <div style={{display:'flex', alignContent:'space-around', alignItems:'center'}}>
                            <TextField id="tag" onChange={(e) => handleNewTag(e)}  variant="outlined" label="Merge Tag" style={{width:'45%', margin:'5px'}} defaultValue="{}" />
                            <TextField id="value" onChange={(e) => handleNewTag(e)} variant="outlined" label="Value" style={{width:'45%', margin:'5px'}} defaultValue="" />
                            </div>
                            <br/>
                            <Button className={classes.primaryButton} onClick={submitNewTag}>Save</Button>
                            <Button id="createTag" variant="outlined"  style={{ marginLeft:'2%'}} onClick={(e) => handleCollapse(e.currentTarget.id, collapseSection.createTag)}>Cancel</Button>
                            <br/>
                            <br/>
                            </div>
                        </Collapse>
                        <Divider/>
                       </Card>
                   </div>
                   
				</Grid>
			</Grid>
            </OrgWrapper>
        </div>
    )
}

export default EmailTemplates