import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import styles from '../../assets/jss/material-onboarding'
import { makeStyles } from '@material-ui/core/styles'
// import Card from '@material-ui/core/Card'

const useStyles = makeStyles(styles)

export default function SingleInquiryForm(props) {
    const { inquiry } = props
    // eslint-disable-next-line no-unused-vars
    const [edit, setEdit] = React.useState(false)
    const classes = useStyles()

    return (
        <div>
            <Grid container spacing={3} justify="center">
                <Grid item xs={6} md={4} lg={3}>
                    <TextField
                        className={classes.singleViewFormField}
                        id="name"
                        label="Name"
                        value={inquiry.name}
                        variant="outlined"
                        disabled={!edit}
                        InputLabelProps={{ shrink: true }}
                        //onChange={(e) => handleChange(e)}
                    />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <TextField
                        className={classes.singleViewFormField}
                        id="phone"
                        label="Phone"
                        value={inquiry.phone}
                        variant="outlined"
                        disabled={!edit}
                        InputLabelProps={{ shrink: true }}
                        //onChange={(e) => handleChange(e)}
                    />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <TextField
                        className={classes.singleViewFormField}
                        id="tripType"
                        label="Trip Type"
                        value={inquiry.tripType}
                        variant="outlined"
                        disabled={!edit}
                        InputLabelProps={{ shrink: true }}
                        //onChange={(e) => handleChange(e)}
                    />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <TextField
                        className={classes.singleViewFormField}
                        id="arivalDate"
                        label="Arival Date"
                        value={inquiry.arivalDate}
                        variant="outlined"
                        disabled={!edit}
                        InputLabelProps={{ shrink: true }}
                        //onChange={(e) => handleChange(e)}
                    />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <TextField
                        className={classes.singleViewFormField}
                        id="tripDuration"
                        label="Night's Staying"
                        value={inquiry.tripDuration}
                        variant="outlined"
                        disabled={!edit}
                        InputLabelProps={{ shrink: true }}

                        //onChange={(e) => handleChange(e)}
                    />
                </Grid>
                <Grid item xs={6} md={4} lg={3}>
                    <TextField
                        className={classes.singleViewFormField}
                        id="partySize"
                        label="Party Size"
                        value={inquiry.partySize}
                        variant="outlined"
                        disabled={!edit}
                        InputLabelProps={{ shrink: true }}

                        //onChange={(e) => handleChange(e)}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <TextField
                        className={classes.singleViewFormField}
                        id="transportation"
                        label="Transportation Needed"
                        value={inquiry.transportation}
                        variant="outlined"
                        disabled={!edit}
                        InputLabelProps={{ shrink: true }}

                        //onChange={(e) => handleChange(e)}
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <TextField
                        className={classes.singleViewFormField}
                        id="email"
                        label="Email"
                        value={inquiry.email}
                        variant="outlined"
                        disabled={!edit}
                        InputLabelProps={{ shrink: true }}

                        //onChange={(e) => handleChange(e)}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField
                        className={classes.singleViewFormField}
                        id="customerMessage"
                        label="Customer Message"
                        value={inquiry.customerMessage}
                        variant="outlined"
                        disabled={!edit}
                        InputLabelProps={{ shrink: true }}
                        multiline
                        rows={4}
                        //onChange={(e) => handleChange(e)}
                    />
                </Grid>
            </Grid>
        </div>
    )
}
