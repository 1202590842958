import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Grid, List, Card, ListItem, ListItemText} from '@material-ui/core'
import { NavLink } from 'react-router-dom';
import styles from '../../assets/jss/material-onboarding.js';
import OrgWrapper from '../../components/layouts/OrgWrapper'


const useStyles = makeStyles(styles);


export default function Settings() {
	const classes = useStyles();

    return (
        <div>
            <OrgWrapper>

			<Grid container>
				<Grid item xs={12} sm={12} md={8}>
					<div >
						{/* <div style={{ float: 'left' }}>
							<h5 className={classes.cardTitle} style={{ marginTop: 10, fontWeight: '500' }}>
								Account
							</h5>
						</div>
						<br/>
						<br/> */}
						<h5 style={{marginTop:'5px'}}>Account</h5>
						<Card>
							<List style={{ padding: '0px' }}>
								<NavLink
									to={{
										pathname: 'settings/business-information',
									}} className={classes.sideLink}>
									<ListItem
										button
										divider
										className={classes.itemLink}
										style={{ padding: '20px 30px', margin: '0px', color: 'black' }}>
										<ListItemText primary="Business Information" />
									</ListItem>
								</NavLink>
								<NavLink
									to={{
										pathname: '/settings',
									}} className={classes.sideLink}>
									<ListItem
										button
										disabled
										divider
										className={classes.itemLink}
										style={{ padding: '20px 30px', margin: '0px', color: 'black' }}>
										<ListItemText primary="Billing Information" />
									</ListItem>
								</NavLink>
							</List>
						</Card>
					</div>
					<br/>
					<br/>
					<div >
						{/* <div style={{ float: 'left' }}>
							<h5 className={classes.cardTitle} style={{ marginTop: 10, fontWeight: '500' }}>
								Security
							</h5>
						</div> */}
						<h5 style={{marginTop:'5px'}}>Security</h5>
						<Card className={classes.listItem}>
							<NavLink
								to={{
									pathname: '/settings',
								}} className={classes.sideLink}>
								<ListItem
									button
									disabled
									divider
									className={classes.itemLink}
									style={{ padding: '20px 30px', margin: '0px', color: 'black' }}>
									<ListItemText primary="Change Password" />
								</ListItem>
							</NavLink>
							<NavLink
								to={{
									pathname: '/settings',
								}} className={classes.sideLink}>
								<ListItem
									button
									disabled
									divider
									className={classes.itemLink}
									style={{ padding: '20px 30px', margin: '0px', color: 'black' }}>
									<ListItemText primary="Multi-factor Authentication" />
								</ListItem>
							</NavLink>
						</Card>
					</div>
				</Grid>
			</Grid>

            </OrgWrapper>
		</div>
    )
}
