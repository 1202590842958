import moment from 'moment'
import { sortBy } from 'lodash'

export const numberWithCommas = (numberToFormat) => numberToFormat.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const formatPhone = (phone) => {
    let val = phone.replace(/\D/g, '')
    let newVal = ''

    if (val.length > 4) {
        phone = val
    }

    if (val.length > 3 && val.length < 7) {
        newVal += val.substr(0, 3) + '-'
        val = val.substr(3)
    }

    if (val.length > 5) {
        newVal += val.substr(0, 3) + '-'
        newVal += val.substr(3, 3) + '-'
        val = val.substr(6)
    }

    newVal += val
    return newVal
}

export const generateLastThirtyDays = (array, now, timePeriod) => {
    let recents = []
    array.forEach((res) => {
        if (moment(res.arivalDate).isBetween(timePeriod, now)) {
            recents.push(res)
        }
    })
    return recents
}

export const formatDates = (array) => {
    let formatted = []
    array.forEach((record) => {
        let date = moment(record).toDate()
        let finalDate = date.setHours(0, 0, 0, 0)
        let gDate = moment(finalDate).toDate()
        let fDate = gDate.toString()
        formatted.push(fDate)
    })
    return formatted
}

export const mobileRender = (width) => {
    if (width > 650) {
        return false
    } else {
        return true
    }
}

export const formatCalendarDates = (array) => {
    let nArray = []
    array.forEach((record) => {
        record.reservationDates.forEach((date) => {
            let nDate = moment(date).toDate()
            let fullDate = nDate.setHours(0, 0, 0, 0)
            let finDate = moment(fullDate).toDate()
            nArray.push(finDate.toString())
        })
        return nArray
    })
    return nArray
}

export const squashAllResDates = (array) => {
    let squashed = []
    array.forEach((record) => {
        record.reservationDates.forEach((d) => {
            squashed.push(d)
        })
        return squashed
    })
    return squashed
}

// This is weak and should be refactored
export const isSameDay = (a, b) => {
    // eslint-disable-next-line eqeqeq
    if (a == b) {
        return true
    } else {
        return false
    }
}
// This is weak and should be refactored
export const getDateRange = (date1, date2) => {
    var d1 = moment(date1).format('MM/DD/YYYY').toString()
    const d2 = moment(date2).format('MM/DD/YYYY').toString()
    const submitDates = []
    while (d1 <= d2) {
        let ndate = moment(d1).add(1, 'days').format('MM/DD/YYYY')
        submitDates.push(d1)
        // eslint-disable-next-line no-redeclare
        var d1 = ndate
    }
    return submitDates
}

export const filterByMonth = (array, month) => {
    let filterMonth = moment(month).format('MMM')
    let newArray = []
    array.filter((record) => {
        if (moment(record.arivalDate).format('MMM') === filterMonth) {
            record.month = filterMonth
            newArray.push(record)
        }
        return newArray
    })
    return newArray
}

export const getDepartureDates = (array) => {
    let nArray = []
    array.forEach((record) => {
        const len = record.reservationDates.length - 1
        nArray.push(record.reservationDates[len])
    })
    return nArray
}

export const getArivalDates = (array) => {
    let nArray = []
    array.forEach((record) => {
        nArray.push(record.reservationDates[0])
    })
    return nArray
}

export const jsDateFormat = (array) => {
    let hArray = []
    array.forEach((date) => {
        let f = moment(date).toDate()
        let j = f.setHours(0, 0, 0, 0)
        let k = moment(j).toDate()
        hArray.push(k.toString())
    })
    return hArray
}

export const submitReservation = async (client) => {
    //setLoading(true)
    const dateRanges = await getDateRange(client.arivalDate, moment(client.arivalDate).add(client.tripDuration, 'days').format('MM/DD/YYYY'))
    const newId = await getNewResId()
    fetch(`${window.bcaApiUrl}/reservations`, {
        method: 'POST',
        body: JSON.stringify({
            number: newId,
            create_date: moment().format('MM/DD/YYYY'),
            note: client.internalNote,
            arival_date: client.arivalDate,
            paid: client.paid,
            payment_method: client.paymentMethod,
            fee: client.fee,
            name: client.name,
            phone: client.phone,
            email: client.email,
            transportation: client.transportation,
            tip: client.tip,
            reservation_dates: dateRanges,
            trip_type: client.tripType,
        }),
    }).then((res) => {
        console.log(res)
        return res
    })
}

export const getNewResId = async () => {
    const apiCall = await fetch(`${window.bcaApiUrl}/reservations/all`, {
        method: 'GET',
    })
    const response = await apiCall.json()
    const sorted = sortBy(response, 'id')
    return sorted[sorted.length - 1].id + 1
}

export const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

export const emailMerge = (template, data) => {
    console.log(template, data)
    var mapObj = {
        '{client_name}':data.name,
        '{business_email}':'baffincabins@gmail.com',
        '{business_phone}':'(361)790-2791',
        '{email_signature}': 'Capt. David Lowry',
        '{available_dates_link}': 'https://baffincharters.com/availability'
     };
     const finalEmail = template.replace(/{client_name}|{business_email}|{email_signature}|{available_dates_link}|{business_phone}/gi, function(matched){
       return mapObj[matched];
     });
     return finalEmail
    // let reName = /{client_name}/gi;
    // let rePhone = /{business_phone}/gi;
    // let reEmail = /{business_email}/gi;
    // let reSignature = /{email_signature}/gi;
    // let rname = await template.replace(reName, data.name)
    // let rphone = await rname.replace(rePhone, '(361)790-2791')
    // let rsig = await rphone.replace(reSignature, 'Capt. David Lowry')
    // let finalEmail = await rsig.replace(reEmail, 'baffincabins@gmail.com').then(() => {
    //     return finalEmail
    // })
}
