import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import styles from '../../assets/jss/material-onboarding'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(styles)


export default function EmailModal(props){
    const classes = useStyles()
    const {open, handleClose, handleEmailChange, handleEmail, selectedEmail, options, mobile, sendEmail} = props

    return(
        <Dialog open={open} onClose={handleClose} aria-labelledby="email-modal" aria-describedby="email-modal" style={{margin:'15px !important'}}>
            <DialogContent style={{margin:'0px'}}>
                <TextField
                    className={mobile ? classes.mobileInputField : classes.inputField}
                    style={{ minWidth: '100%', padding:'10px 0px' }}
                    id="emailTemplate"
                    select
                    label="Email Template"
                    onChange={(e) => handleEmailChange(e)}
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                >
                    {options}
                </TextField>
                <TextField
                    className={mobile ? classes.mobileInputField : classes.inputField}
                    style={{ minWidth: '100%', padding:'10px 0px' }}
                    id="subject"
                    label="Subject"
                    value={selectedEmail.subject}
                    onChange={(e) => handleEmail(e)}
                    variant="outlined"
                >
                </TextField>
                <TextField
                    id="body"
                    style={{ minWidth: '100%', padding:'10px 0px' }}
                    label="Body"
                    multiline
                    onChange={(e) => handleEmail(e)}
                    rows={15}
                    value={selectedEmail.body}
                    variant="outlined"
                    />
                <br />
                <Button variant="contained" className={classes.primaryButton} onClick={() => sendEmail()} style={{marginLeft:'2px', marginRight:'40px'}} >
                    Send
                </Button>
                <Button variant="contained" onClick={handleClose}>
                    Cancel
                </Button>
            </DialogContent>
        </Dialog>
    )
}