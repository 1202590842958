import React from 'react'
//import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
//import InboxIcon from '@material-ui/icons/MoveToInbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
//import MailIcon from '@material-ui/icons/Mail'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import routes from './routes'
import { NavLink } from 'react-router-dom'
import img from '../assets/images/bc.jpg'
import Avatar from '@material-ui/core/Avatar'
// import bimg from '../assets/images/back.svg'
// import { mobileRender } from '../utils'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(2),
        // padding:'5px'
    },
}))

function ResponsiveDrawer(props) {
    const { window, children, active } = props
    const classes = useStyles()
    const theme = useTheme()
    const [mobileOpen, setMobileOpen] = React.useState(false)
    //const [onMobile, setOnMobile] = React.useState(mobileRender(window.innerWidth))

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    //function activeRoute(route) {
    //    return Window.location.pathname === route ? true : false
    //}

    const drawer = (
        <div style={{height:'100%'}}>
            {/*<div className={classes.toolbar}>*/}
            <div style={{ marginTop: '20px' }}>
                <Avatar alt="Baffin Cabins" src={img} style={{ height: '90px', width: '90px', margin: '0 auto' }} />
                <h3 style={{ marginBottom: '0px', marginTop: '10px', textAlign: 'center' }}>Baffin Cabins</h3>
                <p style={{ fontSize: '12px', textAlign: 'center', marginTop: '0px' }}>Est. 2019 | Corpus Christi, TX</p>
            </div>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '80%' }}>
                <List>
                    {routes.slice(0, 5).map((route) => (
                        <NavLink
                            to={route.path}
                            style={{ backgroundColor: route.path === active ? '#b0c5de' : '#fff', textDecoration: 'none', color: 'black' }}
                        >
                            <ListItem button key={route.key}>
                                <ListItemIcon>{route.icon}</ListItemIcon>
                                <ListItemText primary={route.name} />
                            </ListItem>
                        </NavLink>
                    ))}
                </List>
                <List>
                    {routes.slice(7, 10).map((route) => (
                        <NavLink to={route.path} style={{ backgroundColor: route.path === active ? '#b0c5de' : '#fff', textDecoration: 'none' , color: 'black' }}>
                            <ListItem button key={route.key} >
                                <ListItemIcon>{route.icon}</ListItemIcon>
                                <ListItemText primary={route.name} />
                            </ListItem>
                        </NavLink>
                    ))}
                </List>
            </div>
            {/*</div>*/}
        </div>
    )

    const container = window !== undefined ? () => window().document.body : undefined

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Hidden smUp implementation="css">
                <AppBar position="fixed" className={classes.appBar} style={{ backgroundColor: '#4c4c78' }}>
                    <Toolbar>
                        <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" noWrap>
                            Baffin Cabins
                        </Typography>
                    </Toolbar>
                </AppBar>
            </Hidden>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>

            <main className={classes.content}>
                <div className={classes.toolbar} />
                {children}
            </main>
        </div>
    )
}

export default ResponsiveDrawer
