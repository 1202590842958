import React from 'react'
import { sortBy } from 'lodash'
import PersonalInfo from '../views/PersonalInfo'
import TripDetails from '../views/TripDetails'
import PaymentInfo from '../views/PaymentInfo'
import Finished from '../views/Finished'
import Confirm from '../views/Confirm'
import { formatPhone, submitReservation, getNewResId } from '../../../utils'
import moment from 'moment'
import Snackbar from '@material-ui/core/Snackbar'
import Backdrop from '@material-ui/core/Backdrop'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import MuiAlert from '@material-ui/lab/Alert'

const today = moment().format('MM/DD/YYYY').toString()

export default function OnboardingClient() {
    const [obStep, setObStep] = React.useState(1)
    const [loading, setLoading] = React.useState(false)
    const [snackOpen, setSnackOpen] = React.useState(false)
    const [clientInfo, setClientInfo] = React.useState({
        createDate: today,
        arivalDate: today,
        tripDuration: 2,
        internalNote: '',
        reservationId: '',
        paid: 'Yes',
        paymentMethod: 'Card',
        fee: '',
        email: '',
        phone: '',
        name: '',
        transportation: 'No',
        tip: '0',
        tripType: 'Cabin Rental',
    })

    const handleChange = (e, special) => {
        if (special === 'date') {
            setClientInfo({ ...clientInfo, arivalDate: moment(e).format('MM/DD/YYYY') })
        } else if (e.target.id === 'phone') {
            setClientInfo({ ...clientInfo, phone: formatPhone(e.target.value) })
        } else {
            setClientInfo({ ...clientInfo, [e.target.id]: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value })
        }
    }

    const changeView = (viewId) => {
        setObStep(viewId)
    }

    const handleSnackClose = () => {
        setSnackOpen(false)
    }

    const getReservations = async () => {
        const apiCall = await fetch(`${window.bcaApiUrl}/reservations/all`, {
            method: 'GET',
        })
        const response = await apiCall.json()
        const sorted = sortBy(response, 'id')
        setClientInfo({ ...clientInfo, reservationId: sorted[sorted.length - 1].id + 1 })
    }

    const handleNewEntry = async () => {
        const newId = await getNewResId()
        setClientInfo({
            createDate: today,
            arivalDate: today,
            tripDuration: 2,
            internalNote: '',
            reservationId: newId,
            paid: 'Yes',
            paymentMethod: 'Card',
            fee: '',
            email: '',
            phone: '',
            name: '',
            transportation: 'No',
            tip: '0',
            tripType: 'Cabin Rental',
        })
        changeView(1)
    }

    const handleResponse = () => {
        setLoading(false)
        setSnackOpen(true)
        changeView(5)
    }

    const handleSubmit = (clientInfo) => {
        setLoading(true)
        submitReservation(clientInfo)
        handleResponse()
    }

    React.useEffect(() => {
        getReservations()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderViews = (viewId) => {
        switch (viewId) {
            case 1:
                return <PersonalInfo clientInfo={clientInfo} handleChange={handleChange} changeView={changeView} />
            case 2:
                return <TripDetails clientInfo={clientInfo} handleChange={handleChange} changeView={changeView} />
            case 3:
                return <PaymentInfo clientInfo={clientInfo} handleChange={handleChange} changeView={changeView} />
            case 4:
                return <Confirm clientInfo={clientInfo} handleChange={handleChange} changeView={changeView} handleSubmit={handleSubmit} />
            case 5:
                return <Finished clientInfo={clientInfo} changeView={changeView} handleNewEntry={handleNewEntry} />
            default:
                return <PersonalInfo clientInfo={clientInfo} handleChange={handleChange} />
        }
    }

    return (
        <div>
            <Backdrop open={loading} style={{ zIndex: 99999 }} />
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackOpen} autoHideDuration={4000} onClose={handleSnackClose}>
                <MuiAlert elevation={6} variant="filled" severity="success">
                    Saved! Bring home that bacon!!
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose} style={{ marginLeft: '20px' }}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
            {renderViews(obStep)}
        </div>
    )
}
