import React from 'react'
import MUIDataTable from 'mui-datatables'
// import PropTypes from 'prop-types'

export default function InvoicesTable(props) {
    const { onClick, data } = props

    // const formatted = []
    // data.forEach((rec) => {
    //     rec.amount = '$' + rec.amount
    //     formatted.push(rec)
    // })

    // const columns = ["Name", "Name", "Name", "Name", "Name", "Arival Date", "Trip Type", "Phone", "Email", "Message", "Group Size", "Transportation", "Trip Duration", "Status"]


    const columns = [
        {
            name: 'name',
            label: 'Name',
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: 'amount',
            label: 'Amount',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'status',
            label: 'Status',
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: 'arivalDate',
            label: 'Arival Date',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'departureDate',
            label: 'Departure Date',
            options: {
                filter: false,
                sort: true,
            },
        },
    ]



    const options = {
        // filter:true,
        selectableRows: 'none',
        filterType: 'checkbox',
        responsive: 'stacked',
        rowsPerPage: 100,
        onRowClick: (e) => onClick(e),
        page: 1,

        // filterList: [[],[], [], [], [], [], [], [], [], [], ["New Inquiry"]]
      };

    return (
        <div>
            <MUIDataTable title={'Baffin Cabins Invoices'} data={data} columns={columns} options={options} onClick={onClick} />
        </div>
    )
}

// DataTable.propTypes = {
//     reservationData: PropTypes.array,
//     showSingleRecord: PropTypes.bool,
//     mobile: PropTypes.bool,
// }
