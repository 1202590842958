import React from 'react'
import OnboardingLayout from '../onboardingComponents/layout/OnboardingLayout'
import TextField from '@material-ui/core/TextField'
import Continue from '../onboardingComponents/input/Continue'
import Cancel from '../onboardingComponents/input/Cancel'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../assets/jss/material-onboarding'
import { mobileRender } from '../../../utils'

const mobile = mobileRender(window.innerWidth)
const useStyles = makeStyles(styles)

export default function TripDetails(props) {
    const { handleChange, changeView, clientInfo } = props
    const classes = useStyles()

    return (
        <OnboardingLayout title="Trip Details" step={2} previousView="personal-info" changeView={changeView}>
            <div>
                <br />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="arivalDate"
                        label="Arival Date"
                        value={clientInfo.arivalDate}
                        onChange={(e) => handleChange(e, 'date')}
                        className={mobile ? classes.mobileInputField : classes.inputField}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                            id: 'arivalDate',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <br />
                <TextField
                    className={mobile ? classes.mobileInputField : classes.inputField}
                    id="tripType"
                    select
                    label="Trip Type"
                    onChange={(e) => handleChange(e)}
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                >
                    <option key="1" value="Cabin Rental">
                        Cabin Rental
                    </option>
                    <option key="2" value="Gudided Fishing Trip">
                        Guided Fishing Trip
                    </option>
                    <option key="3" value="Cabin Rental & Gudided Trip">
                        Cabin Rental & Guided Trip
                    </option>
                    <option key="4" value="All Inclusive Package">
                        All Inclusive Package
                    </option>
                </TextField>
                <br />
                <TextField
                    className={mobile ? classes.mobileInputField : classes.inputField}
                    id="tripDuration"
                    select
                    label="Trip Duration"
                    value={clientInfo.tripDuration}
                    onChange={(e) => handleChange(e)}
                    SelectProps={{
                        native: true,
                    }}
                    helperText="Number of nights staying."
                    variant="outlined"
                >
                    <option key="1" value={1}>
                        1
                    </option>
                    <option key="2" value={2}>
                        2
                    </option>
                    <option key="3" value={3}>
                        3
                    </option>
                    <option key="4" value={4}>
                        4
                    </option>
                    <option key="5" value={5}>
                        5
                    </option>
                </TextField>
                <br />

                <TextField
                    className={mobile ? classes.mobileInputField : classes.inputField}
                    id="transportation"
                    select
                    label="Transportation Needed"
                    value={clientInfo.transportation}
                    onChange={(e) => handleChange(e)}
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                >
                    <option key="1" value="Yes">
                        Yes
                    </option>
                    <option key="2" value="No">
                        No
                    </option>
                </TextField>
                <br />
                <div className={mobile ? classes.mobileButtonGroup : classes.buttonGroup}>
                    <Cancel changeView={changeView} step={2} text="Back" />
                    <Continue nextView={3} onClick={(e) => changeView(3)} />
                </div>
            </div>
        </OnboardingLayout>
    )
}
