import React from 'react'
import OnboardingLayout from '../onboardingComponents/layout/OnboardingLayout'
import Continue from '../onboardingComponents/input/Continue'
import Cancel from '../onboardingComponents/input/Cancel'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../assets/jss/material-onboarding'
import moment from 'moment'
import { mobileRender } from '../../../utils'

const mobile = mobileRender(window.innerWidth)
const useStyles = makeStyles(styles)

export default function Confirm(props) {
    const { changeView, handleSubmit, clientInfo } = props
    const classes = useStyles()

    return (
        <OnboardingLayout title="Confirm Reservation" step={4} previousView="payment-info" changeView={changeView}>
            <div>
                <p>Ready to submit new reservation?</p>
                <div className={mobile ? classes.mobileConfirmText : classes.confirmText}>
                    <p>{`${clientInfo.name} is scheduled to arive at the cabin on ${moment(clientInfo.arivalDate).format(
                        'dddd, MMMM Do',
                    )}. They are staying ${clientInfo.tripDuration} nights and will be leaving the morning of ${moment(clientInfo.arivalDate)
                        .add(clientInfo.tripDuration, 'days')
                        .format('dddd, MMMM Do')}. Your collecting $${clientInfo.fee} for this trip.`}</p>
                </div>
                <br />
                <div className={mobile ? classes.mobileButtonGroup : classes.buttonGroup}>
                    <Cancel changeView={changeView} step={4} text="Back" />
                    <Continue text="Save" onClick={() => handleSubmit(clientInfo)} />
                </div>
            </div>
        </OnboardingLayout>
    )
}
