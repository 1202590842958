import React, { Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
    keyMetric: {
        fontSize: '35px',
        color: '#006edc',
        marginBottom: '20px',
    },
})

export default function KeyMetric(props) {
    const { number } = props
    const classes = useStyles()

    return (
        <Fragment>
            <span className={classes.keyMetric}>{number}</span>
        </Fragment>
    )
}

KeyMetric.propTypes = {
    number: PropTypes.string,
}
