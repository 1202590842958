import React from 'react'
import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash'

export default function DataTable(props) {
    const { reservationData, mobile, onClick, title} = props

    const formatted = []
    reservationData.forEach((rec) => {
        rec.formattedTip = '$' + rec.tip
        rec.formattedFee = '$' + rec.fee
        formatted.push(rec)
    })

    const columns =
            [
                  {
                      name: 'id',
                      label: 'ID',
                      options: {
                          filter: false,
                          sort: true,
                          display: false,
                      },
                  },
                  {
                      name: 'name',
                      label: 'Name',
                      options: {
                          filter: false,
                          sort: false,
                      },
                  },
                  {
                      name: 'phone',
                      label: 'Phone',
                      options: {
                          filter: false,
                          sort: false,
                          display: mobile ? 'false' : 'true',
                      },
                  },
                  {
                      name: 'arivalDate',
                      label: 'Arival Date',
                      options: {
                          filter: false,
                          sort: true,
                      },
                  },
                  {
                      name: 'tripType',
                      label: 'Trip Type',
                      options: {
                          filter: false,
                          sort: true,
                          display: mobile ? 'false' : 'true',
                      },
                  },
                  {
                      name: 'formattedFee',
                      label: 'Fee',
                      options: {
                          filter: false,
                          sort: true,
                      },
                  },
                  {
                      name: 'paid',
                      label: 'Paid',
                      options: {
                          filter: true,
                          sort: false,
                          display: false,
                      },
                  },

                  {
                      name: 'email',
                      label: 'Email',
                      options: {
                          filter: false,
                          sort: false,
                          display: false,
                      },
                  },
                  {
                      name: 'paymentMethod',
                      label: 'Payment Method',
                      options: {
                          filter: false,
                          sort: false,
                          display: false,
                      },
                  },
                  {
                      name: 'formattedTip',
                      label: 'Tip',
                      options: {
                          filter: false,
                          sort: false,
                          display: false,
                      },
                  },
                  {
                      name: 'note',
                      label: 'Internal Note',
                      options: {
                          filter: false,
                          sort: false,
                          display: false,
                      },
                  },
                  {
                    name: 'createDate',
                    label: 'Create Date',
                    options: {
                        filter: false,
                        sort: false,
                        display: false
                    },
                },
              ]

    // Display options for data table
    const options = {
        filterType: 'dropdown',
        onRowClick: (e) => onClick(e),
        elevation: 5,
        onRowsDelete: (e) => console.log(e),
        selectableRows: 'none',
        responsive: 'standard',
        rowsPerPage: 100
    }

    return (
        <div>
            <MUIDataTable title={title} data={sortBy(formatted, 'arivalDate').reverse()} columns={columns} options={options} onClick={onClick} />
        </div>
    )
}

DataTable.propTypes = {
    reservationData: PropTypes.array,
    showSingleRecord: PropTypes.bool,
    mobile: PropTypes.bool,
}
