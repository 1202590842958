import { sortBy } from 'lodash'

export const getNewTemplateId = async (org) => {
    const apiCall = await fetch(`${window.bcaApiUrl}/cs-emails/${org}`, {
        method: 'GET',
    })
    const newArray = await []
    const response = await apiCall.json()
    await response.forEach(e => {
        newArray.push({id:Number(e.id.split('_')[1])})
    });
    const sorted = await sortBy(newArray, 'id')
    const last = await sorted[sorted.length - 1]
    return `${org}_${Number(last['id']) + 1}`
}

export const getNewTagId = async (org) => {
    const apiCall = await fetch(`${window.bcaApiUrl}/cs-email-merge-tags`, {
        method: 'GET',
    })
    const newArray = await []
    const response = await apiCall.json()
    await response.forEach(e => {
        newArray.push({id:Number(e.id.split('_')[1])})
    });
    const sorted = await sortBy(newArray, 'id')
    const last = await sorted[sorted.length - 1]
    return `${org}_${Number(last['id']) + 1}`
}