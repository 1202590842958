import React from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Card, Grid, Avatar } from '@material-ui/core'
import img from '../assets/images/back.svg'
import loginImg from '../assets/images/undraw_dashboard_nklg.svg'


export default function Auth(props) {
    const { history } = props
    const [pw, setPw] = React.useState('')

    const authenticate = () => {
        if (pw === 'Lowry') {
            sessionStorage.setItem('Auth', 'True')
            sessionStorage.setItem('token', 'bca_17Yx04NplQ')
            history.push('/dashboard')
        }
    }

    const handleChange = (e) => {
        setPw(e.target.value)
    }

    return (
        <div style={{ backgroundImage: `url(${img})`, minHeight: '100vh', backgroundSize:'cover' }}>
            <Grid container justify="center" alignItems="center" style={{ minHeight: '70vh' }}>
                <Grid item xs={10} lg={5}>
                    <Card style={{ backgroundColor: '#c1d0e2', padding: '10px', textAlign: 'center', borderRadius: '20px', marginTop:'10vh' }}>
                        <Avatar alt="Baffin Cabins" variant="square" src={loginImg} style={{ height: '120px', width: '120px', margin: '0 auto' }} />
                        <h2>Charter Safari</h2>
                        <br />
                        <TextField variant="outlined" id="email" label="Email" onChange={(e) => handleChange(e)} style={{ minWidth: '95%' }} />
                        <br />
                        <br />
                        <TextField variant="outlined" id="pw" label="Password" type="password" onChange={(e) => handleChange(e)} style={{ minWidth: '95%' }} />
                        <br />
                        <br />
                        <Button
                            onClick={authenticate}
                            variant="contained"
                            size="large"
                            style={{ minWidth: '95%', marginBottom: '10px', backgroundColor:'#4c4c78', color:'#fff'}}
                        >
                            Sign In
                        </Button>
                        <br />
                        <p>
                            Ready to improve your business ops? <u>Sign Up</u>
                        </p>
                        <br />
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}
