import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {Grid, List, Card, ListItem, ListItemText} from '@material-ui/core'
import { NavLink } from 'react-router-dom';
import styles from '../../assets/jss/material-onboarding.js';
import OrgWrapper from '../../components/layouts/OrgWrapper'


const useStyles = makeStyles(styles);


export default function Marketing() {
	const classes = useStyles();

    return (
        <div>
            <OrgWrapper>

			<Grid container>
				<Grid item xs={12} sm={12} md={8}>
					<div >
						{/* <div style={{ float: 'left' }}>
							<h5 className={classes.cardTitle} style={{ marginTop: 10, fontWeight: '500' }}>
								Account
							</h5>
						</div>
						<br/>
						<br/> */}
						<h5 style={{marginTop:'5px'}}>Integration Settings</h5>
						<Card>
							<List style={{ padding: '0px' }}>
								<NavLink
									to={{
										pathname: 'marketing/linked-accounts',
									}} className={classes.sideLink}>
									<ListItem
										button
										divider
										className={classes.itemLink}
										style={{ padding: '20px 30px', margin: '0px', color: 'black' }}>
										<ListItemText primary="Linked Accounts" />
									</ListItem>
								</NavLink>
							</List>
						</Card>
					</div>
					<br/>
					<br/>
					<div >
						{/* <div style={{ float: 'left' }}>
							<h5 className={classes.cardTitle} style={{ marginTop: 10, fontWeight: '500' }}>
								Security
							</h5>
						</div> */}
						<h5 style={{marginTop:'5px'}}>Core Marketing</h5>
						<Card className={classes.listItem}>
                        <NavLink
								to={{
									pathname: '/marketing/email-templates',
								}} className={classes.sideLink}>
								<ListItem
                                    button
									divider
									className={classes.itemLink}
									style={{ padding: '20px 30px', margin: '0px', color: 'black' }}>
									<ListItemText primary="Email Templates" />
								</ListItem>
							</NavLink>
							<NavLink
								to={{
									pathname: '/marketing',
								}} className={classes.sideLink}>
								<ListItem
									button
									disabled
									divider
									className={classes.itemLink}
									style={{ padding: '20px 30px', margin: '0px', color: 'black' }}>
									<ListItemText primary="Email Campaigns" />
								</ListItem>
							</NavLink>
							<NavLink
								to={{
									pathname: '/marketing',
								}} className={classes.sideLink}>
								<ListItem
									button
									disabled
									divider
									className={classes.itemLink}
									style={{ padding: '20px 30px', margin: '0px', color: 'black' }}>
									<ListItemText primary="Mangage Customer Lists" />
								</ListItem>
							</NavLink>
						</Card>
					</div>
				</Grid>
			</Grid>

            </OrgWrapper>
		</div>
    )
}
