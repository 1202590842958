import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import MuiAlert from '@material-ui/lab/Alert'
import { Snackbar, IconButton } from '@material-ui/core'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function CardPanel(props) {
    const { subTotal, operation, name, email, id, exp, date } = props
    const [snack, setSnack] = React.useState({ open: false, message: '', error: false })

    const handleClose = () => {
        setSnack({ open: false })
    }

    // payment form
    const paymentForm = new window.SqPaymentForm({
        // Initialize the payment form elements

        //TODO: Replace with your sandbox application ID
        applicationId: window.applicationId,
        inputClass: 'sq-input',
        autoBuild: false,
        // Customize the CSS for SqPaymentForm iframe elements
        inputStyles: [
            {
                fontSize: '16px',
                lineHeight: '24px',
                padding: '16px',
                placeholderColor: '#a0a0a0',
                backgroundColor: 'transparent',
            },
        ],

        // Initialize the credit card placeholders
        cardNumber: {
            elementId: 'sq-card-number',
            placeholder: 'Card Number',
        },
        cvv: {
            elementId: 'sq-cvv',
            placeholder: 'CVV',
        },
        expirationDate: {
            elementId: 'sq-expiration-date',
            placeholder: 'MM/YY',
        },
        postalCode: {
            elementId: 'sq-postal-code',
            placeholder: 'Postal',
        },
        // SqPaymentForm callback functions
        callbacks: {
            paymentFormLoaded: function () {
                console.log('form loadeddddd')
            },
            /*
             * callback function: cardNonceResponseReceived
             * Triggered when: SqPaymentForm completes a card nonce request
             */
            cardNonceResponseReceived: function (errors, nonce, cardData) {
                if (errors) {
                    // Log errors from nonce generation to the browser developer console.
                    console.error('Encountered errors:')
                    errors.forEach(function (error) {
                        console.error('  ' + error.message)
                    })
                    //setSnack({ open: true, error: true, message: 'Payment failed to complete!\nTry a new card or contact Capt. David Lowry' })
                    alert('Encountered errors, check browser developer console for more details')
                    return
                }

                fetch(`${window.bcaApiUrl}/process-payment`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        body: {
                            nonce: nonce,
                            amount: (subTotal + subTotal * 0.03) * 100,
                            env: window.env,
                        },
                    }),
                })
                    .catch((err) => {
                        alert('Network error: ' + err)
                        console.log(err)
                    })
                    .then((response) => {
                        console.log(response)
                        if (!response.ok) {
                            return response.json().then((errorInfo) => Promise.reject(errorInfo)) //UPDATE HERE
                        }
                        return response.json() //UPDATE HERE
                    })
                    .then((data) => {
                        console.log(data) //UPDATE HERE
                        setSnack({
                            open: true,
                            error: false,
                            message: 'Payment complete successfully! Check your email for a confirmation and gift certificate.',
                        })
                        if (operation === 'gift') {
                            console.log('here')
                            fetch(`${window.bcaGiftUrl}`, {
                                method: 'POST',
                                body: JSON.stringify({
                                    name: name,
                                    amount: `$${subTotal}`,
                                    expire: exp,
                                    purchase_date: date,
                                    id: id,
                                    email: email,
                                }),
                            })
                        }
                        //alert('Payment complete successfully!\nWe are excited to see you soon!')
                    })
                    .catch((err) => {
                        console.error(err)
                        //setSnack({ open: true, error: true, message: 'Payment failed to complete!\nTry a new card or contact Capt. David Lowry' })
                        alert('Payment failed to complete!\nTry a new card or contact Capt. David Lowry')
                    })
            },
        },
    })

    paymentForm.build()

    function onGetCardNonce(e) {
        console.log('weee')
        // Don't submit the form until SqPaymentForm returns with a nonce
        e.preventDefault()
        // Request a nonce from the SqPaymentForm object
        paymentForm.requestCardNonce()
    }
    // end payment form

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snack.open}
                autoHideDuration={4000}
                onClose={handleClose}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                <Alert onClose={handleClose} severity={snack.error ? 'error' : 'success'}>
                    {snack.message}
                </Alert>
            </Snackbar>
            <div id="form-container">
                <div id="sq-card-number"></div>
                <div className="third" id="sq-expiration-date"></div>
                <div className="third" id="sq-cvv"></div>
                <div className="third" id="sq-postal-code"></div>
                <button id="sq-creditcard" className="button-credit-card" onClick={(e) => onGetCardNonce(e)}>
                    Complete Payment
                </button>
            </div>
        </div>
    )
}
