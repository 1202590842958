import React from 'react'
import OnboardingLayout from '../onboardingComponents/layout/OnboardingLayout'
import TextField from '@material-ui/core/TextField'
import Continue from '../onboardingComponents/input/Continue'
import Cancel from '../onboardingComponents/input/Cancel'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../../assets/jss/material-onboarding'
import { mobileRender } from '../../../utils'

const mobile = mobileRender(window.innerWidth)
const useStyles = makeStyles(styles)

export default function PaymentInfo(props) {
    const { handleChange, changeView } = props
    const classes = useStyles()

    return (
        <OnboardingLayout title="Payment Info" step={3} previousView="trip-details" changeView={changeView}>
            <div>
                <br />
                <TextField
                    className={mobile ? classes.mobileInputField : classes.inputField}
                    id="paid"
                    select
                    label="Paid"
                    onChange={(e) => handleChange(e)}
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                >
                    <option key="1" value="Yes">
                        Yes
                    </option>
                    <option key="2" value="No">
                        No
                    </option>
                </TextField>
                <br />
                <TextField
                    className={mobile ? classes.mobileInputField : classes.inputField}
                    id="fee"
                    label="Fee Charged"
                    inputProps={{ type: 'number' }}
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                />
                <br />
                <TextField
                    className={mobile ? classes.mobileInputField : classes.inputField}
                    id="tip"
                    label="Tip"
                    inputProps={{ type: 'number' }}
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                />
                <br />
                <TextField
                    className={mobile ? classes.mobileInputField : classes.inputField}
                    id="paymentMethod"
                    select
                    label="Payment Method"
                    onChange={(e) => handleChange(e)}
                    SelectProps={{
                        native: true,
                    }}
                    variant="outlined"
                >
                    <option key="1" value="Card">
                        Card
                    </option>
                    <option key="2" value="Cash">
                        Cash
                    </option>
                    <option key="2" value="Check">
                        Check
                    </option>
                    <option key="2" value="Split">
                        Split
                    </option>
                </TextField>
                <div className={mobile ? classes.mobileButtonGroup : classes.buttonGroup}>
                    <Cancel changeView={changeView} step={3} text="Back" />
                    <Continue onClick={(e) => changeView(4)} />
                </div>
            </div>
        </OnboardingLayout>
    )
}
