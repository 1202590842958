import React from 'react'
import { validateAuth } from '../utils/auth'
import { sortBy, sumBy } from 'lodash'
//import { NavLink } from 'react-router-dom'
import 'date-fns'
import moment from 'moment'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
//import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Snackbar from '@material-ui/core/Snackbar'
import Backdrop from '@material-ui/core/Backdrop'
// import SingleViewModal from '../components/SingleViewModal'
import {
    numberWithCommas,
    jsDateFormat,
    getArivalDates,
    getDepartureDates,
    generateLastThirtyDays,
    mobileRender,
    isSameDay,
    formatCalendarDates,
} from '../utils'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
//import MetricCard from '../components/MetricCard'
import MuiAlert from '@material-ui/lab/Alert'
import DataTable from '../components/DataTable'
import OrgWrapper from '../components/layouts/OrgWrapper'
//import Card from '@material-ui/core/Card'
//import CardHeader from '@material-ui/core/CardHeader'
//import CardBody from '@material-ui/core/CardBody'
import Tabs from '../components/Tabs'
import OnboardingClient from '../components/onboarding/onboardingComponents/OnboardingClient'
import CircularProgress from '@material-ui/core/CircularProgress'

const today = moment().format('MM/DD/YYYY')
const thrityDaysAgo = moment().subtract(30, 'days').format('MM/DD/YYYY')
const lastM = moment(today).subtract(1, 'months').toDate()
const nextM = moment(today).add(1, 'months').toDate()

console.log(lastM)
const useStyles = makeStyles({
    tableHeader: {
        fontWeight: '700',
    },
    calendar: {
        boxShadow: '0px 30px 40px 0px #10245e33',
        margin: '0 auto',
        border: '1px, solid, black',
    },
    reservedDate: {
        backgroundColor: '#c16666b0',
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'red',
        position: 'relative',
        borderTop: '100px solid red',
        borderRight: '100px solid transparent',
    },
    lastReservedDate: {
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: '#c16666b0',
        position: 'relative',
        borderTop: '100px solid #c16666b0',
        borderRight: '100px solid transparent',
        '&:after': {
            content: '""',
            position: 'absolute',
            borderBottom: '29px solid #c16666b0',
            width: '90px',
            transform: 'rotate(36deg)',
            transformOrigin: '0% 0%',
            bottom: '10px',
            padding: '10px',
            left: '0px',
        },
    },
})

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function Home(props) {
    const classes = useStyles()
    const { history } = props
    const [reservationData, setReservationData] = React.useState([])
    // const [selectedRes, setSelectedRes] = React.useState('')
    // const [open, setOpen] = React.useState(false)
    const [snackOpen, setSnackOpen] = React.useState(false)
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = React.useState(false)
    const [mobile, setMobile] = React.useState(true)
    // eslint-disable-next-line no-unused-vars
    const [lastThirtyDaysTrips, setLastThirtyDaysTrips] = React.useState([])
    // eslint-disable-next-line no-unused-vars
    const [lastThirtyRev, setLastThirtyRev] = React.useState('')
    const [calendarDates, setCalendarDates] = React.useState([])
    const [departureDates, setDepartureDates] = React.useState([])
    const [arivalDates, setArivalDates] = React.useState([])
    // eslint-disable-next-line no-unused-vars
    const [totalRev, setTotalRev] = React.useState('')

    // const handleClose = () => {
    //     setOpen(false)
    // }

    const getReservations = async () => {
        // getting records from Dynamodb
        setLoading(true)
        let apiCall = await fetch(`${window.bcaApiUrl}/reservations/all`, {
            method: 'GET',
        })
        let response = await apiCall.json()
        let sorted = sortBy(response, 'id')
        setReservationData(sorted)
        let total = sumBy(sorted, 'fee')
        setTotalRev('$' + numberWithCommas(total))
        let recentTrips = await generateLastThirtyDays(sorted, today, thrityDaysAgo)
        let recentRev = sumBy(recentTrips, 'fee')
        setLastThirtyDaysTrips(recentTrips)
        setLastThirtyRev('$' + numberWithCommas(recentRev))
        let displayDates = await formatCalendarDates(sorted)
        setCalendarDates(displayDates)
        let dArray = await getDepartureDates(sorted)
        let aArray = await getArivalDates(sorted)
        let fArray = await jsDateFormat(dArray)
        let bArray = await jsDateFormat(aArray)

        setDepartureDates(fArray)
        setArivalDates(bArray)

        setMobile(mobileRender(window.innerWidth))
    }

    const calendarClick = (value) => {
        console.log(value)
    }

    // const showSingleRecord = (e) => {
    //     setOpen(true)
    //     setSelectedRes(e)
    // }

    const handleSnackClose = () => {
        setSnackOpen(false)
    }

    const handleResClick = (e) => {
        history.push(`single-reservation?id=${e[0]}&date=${e[11]}`)
        console.log(e)
    }

    // The useEffect function fires first thing
    React.useEffect(() => {
        validateAuth(history)
        getReservations().then(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // edit this function to impliment both classes based on last date in array
    const tileDisabled = ({ date }) => {
        if (departureDates.find((d) => isSameDay(d, date)) && !arivalDates.find((d) => isSameDay(d, date))) {
            return classes.lastReservedDate
        } else if (calendarDates.find((d) => isSameDay(d, date))) {
            return classes.reservedDate
        }
    }

    const avail = (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} style={{ marginTop: '20px' }}>
                <Calendar
                    value={lastM}
                    calendarType="US"
                    className={[classes.calendar, classes.lastTripDate]}
                    maxDetail="month"
                    locale="en-US"
                    tileClassName={tileDisabled}
                    onClickDay={calendarClick}
                    prev2AriaLabel={null}
                    prev2Label={null}
                    next2AriaLabel={null}
                    next2Label={null}
                    navigationAriaLabel={null}
                />
            </Grid>
            <Grid item xs={12} md={4} style={{ marginTop: '20px' }}>
                <Calendar
                    value={new Date()}
                    calendarType="US"
                    className={[classes.calendar, classes.lastTripDate]}
                    maxDetail="month"
                    locale="en-US"
                    tileClassName={tileDisabled}
                    onClickDay={calendarClick}
                    prev2AriaLabel={null}
                    prev2Label={null}
                    next2AriaLabel={null}
                    next2Label={null}
                    navigationAriaLabel={null}
                />
            </Grid>
            <Grid item xs={12} md={4} style={{ marginTop: '20px' }}>
                <Calendar
                    value={nextM}
                    calendarType="US"
                    className={[classes.calendar, classes.lastTripDate]}
                    maxDetail="month"
                    locale="en-US"
                    tileClassName={tileDisabled}
                    onClickDay={calendarClick}
                    prev2AriaLabel={null}
                    prev2Label={null}
                    next2AriaLabel={null}
                    next2Label={null}
                    navigationAriaLabel={null}
                />
            </Grid>
        </Grid>
    )

    return (
        <OrgWrapper>
            <Backdrop open={loading} style={{ zIndex: 99999 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={snackOpen}
                autoHideDuration={4000}
                onClose={handleSnackClose}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                <Alert onClose={handleSnackClose} severity="success">
                    Saved! Bring home that bacon!!
                </Alert>
            </Snackbar>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Tabs
                            mobile={mobile}
                            itemOne={
                                <DataTable
                                    reservationData={reservationData}
                                    onClick={handleResClick}
                                    mobile={mobile}
                                    view="reservations"
                                    title="Baffin Cabins Reservations"
                                />
                            }
                            itemTwo={avail}
                            itemThree={<OnboardingClient />}
                        />
                    </Grid>
                </Grid>

                <br />
                <br />
                {/* <SingleViewModal open={open} selectedRes={selectedRes} handleClose={handleClose} /> */}
            </div>
        </OrgWrapper>
    )
}
