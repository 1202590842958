import React from 'react'
import MUIDataTable from 'mui-datatables'
// import PropTypes from 'prop-types'

export default function InquiryTable(props) {
    const { onClick, data, mobile} = props


    // Display options for data table
    // const options = {
    //     filterType: 'dropdown',
        
    //     elevation: 5,
    //     onRowsDelete: (e) => console.log(e),
    //     selectableRows: 'none',
    //     responsive: 'standard',
    // }

    const formatted = []
    data.forEach((rec) => {
        formatted.push(rec)
    })


    // const columns = ["Name", "Name", "Name", "Name", "Name", "Arival Date", "Trip Type", "Phone", "Email", "Message", "Group Size", "Transportation", "Trip Duration", "Status"]


    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: {
                filter: false,
                sort: true,
                display: false,
            },
        },
        {
            name: 'name',
            label: 'Name',
            options: {
                filter: false,
                sort: false,
            },
        },
        {
            name: 'arivalDate',
            label: 'Arival Date',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'tripType',
            label: 'Trip Type',
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: 'phone',
            label: 'Phone',
            options: {
                filter: false,
                sort: false,
                display: mobile ? 'false' : 'true',
            },
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: false,
                sort: false,
                display: mobile ? 'false' : 'true',
            },
        },
        {
            name: 'customerMessage',
            label: 'Customer Message',
            options: {
                filter: false,
                sort: false,
                display: mobile ? 'false' : 'true',
            },
        },
        {
            name: 'partySize',
            label: 'Party Size',
            options: {
                filter: false,
                sort: false,
                display: mobile ? 'false' : 'true',
            },
        },
        {
            name: 'transportation',
            label: 'Transportation',
            options: {
                filter: false,
                sort: false,
                display: mobile ? 'false' : 'true',
            },
        },
        {
            name: 'tripDuration',
            label: 'Trip Duration',
            options: {
                filter: false,
                sort: false,
                display: mobile ? 'false' : 'true',
            },
        },
        {
          name: 'inquiryStatus',
          label: 'Status',
          options: {
              filter: true,
              filterOptions: {
                // renderValue: v => v.toLowerCase(),
                // logic: (location, filters, row) => {
                //     if(filters.label === 'New Inquiry'){
                //         return true
                //     }
                //   },
              },
              sort: false,
              display: mobile ? 'false' : 'true',
              
          },
      },
    ]



    const options = {
        // filter:true,
        selectableRows: 'none',
        filterType: 'checkbox',
        responsive: 'stacked',
        rowsPerPage: 100,
        onRowClick: (e) => onClick(e),
        page: 1,

        // filterList: [[],[], [], [], [], [], [], [], [], [], ["New Inquiry"]]
      };

    return (
        <div>
            <MUIDataTable title={'Baffin Cabins Inquiries'} data={formatted} columns={columns} options={options} onClick={onClick} />
        </div>
    )
}

// DataTable.propTypes = {
//     reservationData: PropTypes.array,
//     showSingleRecord: PropTypes.bool,
//     mobile: PropTypes.bool,
// }
