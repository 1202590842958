import React from 'react'
import { validateAuth } from '../../utils/auth'
import { sortBy } from 'lodash'
import OrgWrapper from '../../components/layouts/OrgWrapper'
// import DataTable from '../components/DataTable'
import InquiryTable from '../../components/InquiryTable'
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop'
import {mobileRender} from '../../utils'

const mobile = mobileRender(window.innerWidth)
export default function Inquires(props) {
    const { history } = props
    const [inquires, setInquires] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    // const [mobile, setMobile] = React.useState('')
    const getInquiries = async () => {
        setLoading(true)
        const apiCall = await fetch(`${window.bcaApiUrl}/inquiries/all`, {
            method: 'GET',
        })
        const response = await apiCall.json()
        const sorted = sortBy(response, 'id')
        setInquires(sorted)
    }

    const handleClick = (e) => {
        history.push(`single-inquiry?id=${e[0]}`)
    }

    React.useEffect(() => {
        validateAuth(history)
        getInquiries().then(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <OrgWrapper>
            <Backdrop open={loading} style={{ zIndex: 99999 }} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div>
                <InquiryTable data={inquires} onClick={handleClick} mobile={mobile}/>
            </div>
        </OrgWrapper>
    )
}
